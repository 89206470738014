<template>
  <div class="table-item item--status" :class="main ? 'group-start' : ''">
    <div class="item">
      <status-indicator :sameSize="true" :instance="item" />
    </div>
  </div>
</template>

<script>
import StatusIndicator from "../StatusIndicator.vue";

export default {
  components: {
    StatusIndicator,
  },
  props: {
    item: {
      type: Object,
    },
    main: {
      type: Boolean,
      default: false,
    },
  },
};
</script>