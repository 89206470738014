<template>
  <div>
    <v-btn
      :class="[
        $vuetify.breakpoint.mobile ? 'ml-2' : 'ml-4',
        disabled ? 'button-disabled' : '',
      ]"
      @click="changeListStyle(0)"
      :color="listStyle === 0 ? 'primary' : ''"
      :outlined="listStyle === 0"
      icon
      tile
    >
      <v-icon>$grid</v-icon>
    </v-btn>
    <v-btn
      :class="[
        $vuetify.breakpoint.mobile ? 'ml-2' : 'ml-4',
        disabled ? 'button-disabled' : '',
      ]"
      @click="changeListStyle(1)"
      :color="listStyle === 1 ? 'primary' : ''"
      :outlined="listStyle === 1"
      icon
      tile
    >
      <v-icon>$list</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    listStyle: Number,
    disabled: Boolean,
  },
  methods: {
    changeListStyle(style) {
      this.$emit("update:listStyle", style);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  width: 36px;
  height: 36px;
  transition: opacity 0.24s ease;
  &.primary--text {
    border-color: var(--v-primary-base) !important;
  }
  &.button-disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
}
</style>
