<template>
  <svg width="81" height="70" viewBox="0 0 81 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.12766 46.7122C-6.3786 36.8058 9.93204 5.38432 33.0847 1.39583C59.0585 -3.07906 77.2824 16.377 72.7751 38.8325C67.7976 63.5903 35.1276 75.9773 25.8211 67.1735C20.341 62.0014 29.4854 55.6782 22.7406 49.3225C16.0121 42.9669 9.0241 51.333 4.12766 46.7122Z" fill="var(--v-illustrations-lighten2)"/>
    <path d="M15 12L63 12C63.9901 12 68 14.0264 68 15V23H12L12 15C12 13.8944 13.8944 12 15 12Z" fill="var(--v-illustrations-lighten1)"/>
    <path d="M65.815 59.3086L12.8785 59.3086C11.8884 59.3086 11.0964 58.5 11.0964 57.5264V39.4904L11.0964 21.4544L67.8281 21.4544V57.2954C67.8281 58.401 66.9205 59.3086 65.815 59.3086Z" fill="var(--v-illustrations-lighten3)"/>
    <path d="M14 22L66 22" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    <path d="M17 18.5C17.8284 18.5 18.5 17.8284 18.5 17C18.5 16.1716 17.8284 15.5 17 15.5C16.1716 15.5 15.5 16.1716 15.5 17C15.5 17.8284 16.1716 18.5 17 18.5Z" stroke="var(--v-illustrations-darken2)" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M23 18.5C23.8284 18.5 24.5 17.8284 24.5 17C24.5 16.1716 23.8284 15.5 23 15.5C22.1716 15.5 21.5 16.1716 21.5 17C21.5 17.8284 22.1716 18.5 23 18.5Z" stroke="var(--v-illustrations-darken2)" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M29 18.5C29.8284 18.5 30.5 17.8284 30.5 17C30.5 16.1716 29.8284 15.5 29 15.5C28.1716 15.5 27.5 16.1716 27.5 17C27.5 17.8284 28.1716 18.5 29 18.5Z" stroke="var(--v-illustrations-darken2)" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M63 15H37C36.2158 15 35 15.8954 35 17C35 18.1046 36.2158 19 37 19H63C63.7842 19 65 18.1046 65 17C65 15.8954 63.7842 15 63 15Z" fill="var(--v-illustrations-darken3)"/>
    <path d="M64.0045 12H15.9955C13.7888 12 12 13.8285 12 16.0841V55.9159C12 58.1715 13.7888 60 15.9955 60H64.0045C66.2112 60 68 58.1715 68 55.9159V16.0841C68 13.8285 66.2112 12 64.0045 12Z" stroke="var(--v-illustrations-darken2)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M32.3047 38.0009C32.3047 41.0594 34.0846 43.6917 36.6668 44.9452L32.9816 34.8672C32.5554 35.8198 32.3047 36.8978 32.3047 38.0009Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M45.2143 37.5993C45.2143 36.6467 44.8634 35.9949 44.5876 35.4684C44.1865 34.8416 43.8355 34.2901 43.8355 33.6634C43.8355 32.9614 44.362 32.2845 45.1391 32.2845C45.1642 32.2845 45.2143 32.2845 45.2394 32.2845C43.8606 31.0311 42.0305 30.2539 40.0249 30.2539C37.3174 30.2539 34.9609 31.6327 33.582 33.7386C33.7575 33.7386 33.933 33.7386 34.0834 33.7386C34.8857 33.7386 36.1391 33.6383 36.1391 33.6383C36.5653 33.6383 36.6155 34.2149 36.1893 34.265C36.1893 34.265 35.7631 34.3152 35.3118 34.3403L38.1196 42.7135L39.7993 37.6494L38.596 34.3653C38.1698 34.3653 37.7937 34.2901 37.7937 34.2901C37.3675 34.2901 37.4177 33.6383 37.8439 33.6634C37.8439 33.6634 39.1224 33.7637 39.8745 33.7637C40.6767 33.7637 41.9302 33.6634 41.9302 33.6634C42.3564 33.6634 42.4065 34.24 41.9804 34.2901C41.9804 34.2901 41.5542 34.3403 41.1029 34.3653L43.8856 42.6634L44.6628 40.0812C44.9887 39.0032 45.2394 38.2511 45.2394 37.5742L45.2143 37.5993Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M40.1502 38.6758L37.8438 45.3944C38.5457 45.595 39.2727 45.7203 40.0248 45.7203C40.9273 45.7203 41.7797 45.5699 42.5819 45.2942C42.5819 45.2691 42.5318 45.219 42.5318 45.1939L40.1502 38.7008V38.6758Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M46.7932 34.2891C46.8182 34.5398 46.8433 34.7905 46.8433 35.0913C46.8433 35.8684 46.6929 36.7459 46.2667 37.849L43.9102 44.6679C46.2166 43.3392 47.7458 40.8322 47.7458 37.9994C47.7458 36.6456 47.3948 35.3921 46.7932 34.2891Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M40 29C35.0362 29 31 33.0362 31 38C31 42.9638 35.0362 47 40 47C44.9638 47 49 42.9638 49 38C49 33.0362 44.9638 29 40 29ZM40 46.5989C35.2618 46.5989 31.4011 42.7382 31.4011 38C31.4011 33.2618 35.2618 29.4011 40 29.4011C44.7382 29.4011 48.5989 33.2618 48.5989 38C48.5989 42.7382 44.7382 46.5989 40 46.5989Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M76 44L76 64" stroke="#D14F4F" stroke-width="2.12232" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M54 44L54 64" stroke="#D14F4F" stroke-width="2.12232" stroke-miterlimit="10" stroke-linecap="round"/>
    <rect x="50" y="45" width="31" height="8" rx="0.825069" fill="white"/>
    <mask id="mask0_774_56575" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="49" y="45" width="32" height="8">
    <path d="M80.1877 52.4694L49.8123 52.4694C49.7184 52.4694 49.5306 52.3595 49.5306 52.0769V45.9231C49.5306 45.6405 49.7184 45.5306 49.8123 45.5306L80.1877 45.5306C80.2816 45.5306 80.4694 45.6405 80.4694 45.9231V52.0769C80.4694 52.3595 80.2816 52.4694 80.1877 52.4694Z" fill="#EEEEF4" stroke="#FF004F" stroke-width="1.06116" stroke-linecap="round" stroke-linejoin="round"/>
    </mask>
    <g mask="url(#mask0_774_56575)">
    <path d="M85.5214 39.8834L76.3797 62.6518L75.3608 60.1142L84.5025 37.3458L85.5214 39.8834Z" fill="#D14F4F" stroke="#D14F4F" stroke-width="1.06116" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M79.1725 39.8836L70.0473 62.6507L69.0287 60.114L78.1539 37.3469L79.1725 39.8836Z" fill="#D14F4F" stroke="#D14F4F" stroke-width="1.06116" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M72.8456 39.8834L63.704 62.6517L62.685 60.1142L71.8267 37.3458L72.8456 39.8834Z" fill="#D14F4F" stroke="#D14F4F" stroke-width="1.06116" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M66.5006 39.8836L57.3754 62.6507L56.3568 60.114L65.4821 37.3469L66.5006 39.8836Z" fill="#D14F4F" stroke="#D14F4F" stroke-width="1.06116" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M60.1698 39.8834L51.0282 62.6518L50.0093 60.1142L59.1509 37.3458L60.1698 39.8834Z" fill="#D14F4F" stroke="#D14F4F" stroke-width="1.06116" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M53.8253 39.8825L44.6996 62.6507L43.6811 60.1142L52.8201 37.3524L53.8253 39.8825Z" fill="#D14F4F" stroke="#D14F4F" stroke-width="1.06116" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M80.1539 52.4694L49.8461 52.4694C49.7336 52.4694 49.5306 52.3423 49.5306 52.0385V45.9615C49.5306 45.6577 49.7336 45.5306 49.8461 45.5306L80.1539 45.5306C80.2664 45.5306 80.4694 45.6577 80.4694 45.9615V52.0385C80.4694 52.3423 80.2664 52.4694 80.1539 52.4694Z" stroke="#D14F4F" stroke-width="1.06116" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
    
    
</template>

<script>
export default {};
</script>

<style>
</style>