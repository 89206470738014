<template>
  <div class="step-indicator">
    <div
      class="step__item step__item--active"
      v-for="item in previousSteps"
      :key="'previous-' + item"
    ></div>
    <div class="step__item step__item--active step__item--current"></div>
    <div
      class="step__item"
      v-for="item in nextSteps"
      :key="'next-' + item"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    maxSteps: {
      type: Number,
      default: 0,
    },
    currentStep: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    previousSteps() {
      return this.currentStep - 1;
    },
    nextSteps() {
      return this.maxSteps - this.currentStep;
    },
  },
};
</script>
<style lang="scss" scoped>
.step-indicator {
  display: flex;
  align-items: center;
  .step__item {
    width: 6px;
    height: 6px;
    background-color: var(--v-gray-lighten3);
    border-radius: 3px;
    margin: 0 2px;
    transition: background-color 0.24s ease, width 0.24s ease;
    &--active {
      background-color: var(--v-primary-base);
    }
    &--current {
      background-color: var(--v-primary-base);
      width: 20px;
    }
  }
}
</style>
