<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9664 1.88348C15.2844 1.22276 19.6107 0.600411 23.9369 0C23.9397 3.79164 23.9369 7.58053 23.9397 11.3722C19.6162 11.3886 15.2927 11.4544 10.9664 11.4681C10.9637 8.27142 10.9637 5.07745 10.9664 1.88348ZM0 3.4133C3.25154 2.91158 6.51707 2.47841 9.78232 2.06991C9.78506 5.21453 9.78506 8.35641 9.7878 11.501C6.52529 11.4983 3.26251 11.5476 0 11.5394V3.4133ZM0 12.5291C3.26004 12.5182 6.51953 12.5703 9.7793 12.5648C9.7793 15.7176 9.78753 18.8705 9.78232 22.0233C6.52529 21.5405 3.26251 21.1186 0 20.6772V12.5291ZM10.9472 12.6552H23.9369C23.9424 16.4359 23.9369 20.2166 23.9369 24C19.6189 23.353 15.2927 22.7526 10.9664 22.1661C10.9609 18.9966 10.9527 15.8273 10.9472 12.6552Z"
      fill="#00ADEF"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>