<template>
    <div>
        <svg v-if="$parent.size == '20'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 18C14.7581 18 18 14.7581 18 10C18 5.24185 14.7581 2 10 2C5.24185 2 2 5.24185 2 10C2 14.7581 5.24185 18 10 18Z" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.5 10H17.5" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 2C12.0844 4.28196 13.0606 6.91003 13.125 10C13.0606 13.09 12.0844 15.718 10 18C7.9156 15.718 6.93937 13.09 6.875 10C6.93937 6.91003 7.9156 4.28196 10 2Z" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-else
            :width="width"
            :height="height"
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 18C14.7581 18 18 14.7581 18 10C18 5.24185 14.7581 2 10 2C5.24185 2 2 5.24185 2 10C2 14.7581 5.24185 18 10 18Z" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.5 10H17.5" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 2C12.0844 4.28196 13.0606 6.91003 13.125 10C13.0606 13.09 12.0844 15.718 10 18C7.9156 15.718 6.93937 13.09 6.875 10C6.93937 6.91003 7.9156 4.28196 10 2Z" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>

</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<style>
</style>