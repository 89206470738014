<template>
  <svg width="65" height="90" viewBox="0 0 65 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.8755 68.9589C-6.39797 60.2297 7.99115 32.5181 28.4102 29.0133C51.3109 25.0732 67.3762 42.2269 63.4143 62.0147C59.0388 83.8487 30.2388 94.7548 22.0102 87.0052C17.1775 82.4555 25.232 76.861 19.2891 71.2664C13.3462 65.6718 7.20747 73.0297 2.8755 68.9589Z" fill="var(--v-illustrations-lighten2)"/>
    <path d="M58.4482 28H18.2774C17.4817 28 16.7134 28.1873 16.0274 28.5351L8.20732 32.3612C7.24695 32.7358 7 34.2341 7 35.5987V73.5385C7 74.903 8.17988 76 9.60671 76C9.60671 76 14.9299 71.9866 17.5915 69.7659H58.3933C59.8476 69.7659 61 68.6154 61 67.2241V30.5686C61 29.1505 59.8201 28.0268 58.3933 28.0268L58.4482 28Z" fill="var(--v-illustrations-lighten1)"/>
    <path d="M51.9132 76.8207C50.4705 76.8207 49.1094 75.5413 49.1094 74.2074V35.6343C49.1094 34.3004 49.1094 32.3949 50.5793 32.3949L58.5009 28.1211C59.9436 28.1211 61.0869 29.21 61.0869 30.5438V67.5926C61.0869 68.5998 60.6514 69.5525 59.8619 70.2058C57.3031 72.3564 51.9132 76.7663 51.9132 76.7663V76.8207Z" fill="var(--v-illustrations-darken3)" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M50.3817 33H9.61833C8.17227 33 7 34.1723 7 35.6183V76.3817C7 77.8277 8.17227 79 9.61833 79H50.3817C51.8277 79 53 77.8277 53 76.3817V35.6183C53 34.1723 51.8277 33 50.3817 33Z" fill="var(--v-illustrations-lighten3)" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.3164 55.2621C21.3164 58.5831 23.2491 61.4414 26.053 62.8025L22.0514 51.8594C21.5886 52.8938 21.3164 54.0643 21.3164 55.2621Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M35.3652 54.8236C35.3652 53.7892 34.9841 53.0814 34.6847 52.5098C34.2492 51.8292 33.868 51.2304 33.868 50.5498C33.868 49.7876 34.4397 49.0526 35.2836 49.0526C35.3108 49.0526 35.3652 49.0526 35.3925 49.0526C33.8953 47.6915 31.9081 46.8477 29.7303 46.8477C26.7904 46.8477 24.2316 48.3449 22.7344 50.6315C22.9249 50.6315 23.1155 50.6315 23.2788 50.6315C24.1499 50.6315 25.511 50.5226 25.511 50.5226C25.9738 50.5226 26.0282 51.1487 25.5654 51.2031C25.5654 51.2031 25.1027 51.2576 24.6127 51.2848L27.6615 60.3768L29.4854 54.8781L28.1787 51.312C27.7159 51.312 27.3076 51.2304 27.3076 51.2304C26.8448 51.2304 26.8993 50.5226 27.3621 50.5498C27.3621 50.5498 28.7504 50.6587 29.567 50.6587C30.4381 50.6587 31.7992 50.5498 31.7992 50.5498C32.262 50.5498 32.3164 51.1759 31.8536 51.2304C31.8536 51.2304 31.3909 51.2848 30.9009 51.312L33.9225 60.3224L34.7664 57.5186C35.1202 56.348 35.3925 55.5314 35.3925 54.7964L35.3652 54.8236Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M29.8638 55.9961L27.3594 63.2915C28.1216 63.5093 28.911 63.6454 29.7277 63.6454C30.7076 63.6454 31.6332 63.4821 32.5043 63.1826C32.5043 63.1554 32.4498 63.101 32.4498 63.0737L29.8638 56.0233V55.9961Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M37.0797 51.2305C37.1069 51.5027 37.1342 51.7749 37.1342 52.1016C37.1342 52.9454 36.9708 53.8982 36.5081 55.0959L33.9492 62.5002C36.4536 61.0575 38.1141 58.3353 38.1141 55.2593C38.1141 53.7893 37.733 52.4282 37.0797 51.2305Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M29.7023 45.4883C24.3124 45.4883 19.9297 49.871 19.9297 55.2609C19.9297 60.6508 24.3124 65.0335 29.7023 65.0335C35.0922 65.0335 39.4749 60.6508 39.4749 55.2609C39.4749 49.871 35.0922 45.4883 29.7023 45.4883ZM29.7023 64.5979C24.5574 64.5979 20.3652 60.4058 20.3652 55.2609C20.3652 50.116 24.5574 45.9238 29.7023 45.9238C34.8472 45.9238 39.0393 50.116 39.0393 55.2609C39.0393 60.4058 34.8472 64.5979 29.7023 64.5979Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M15 32V29.0202C15 27.9091 16.0485 27 17.3301 27H24.6699C25.9515 27 27 27.9091 27 29.0202V32" fill="var(--v-illustrations-lighten3)"/>
    <path d="M15 32V29.0202C15 27.9091 16.0485 27 17.3301 27H24.6699C25.9515 27 27 27.9091 27 29.0202V32" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M44.6755 17H37.3245C36.0407 17 35 17.8992 35 19.0084V26.9916C35 28.1008 36.0407 29 37.3245 29H44.6755C45.9593 29 47 28.1008 47 26.9916V19.0084C47 17.8992 45.9593 17 44.6755 17Z" fill="var(--v-illustrations-lighten1)" stroke="var(--v-illustrations-darken2)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M30.6755 1H23.3245C22.0407 1 21 1.89918 21 3.00837V10.9916C21 12.1008 22.0407 13 23.3245 13H30.6755C31.9593 13 33 12.1008 33 10.9916V3.00837C33 1.89918 31.9593 1 30.6755 1Z" fill="var(--v-illustrations-lighten3)" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M55 84C59.9706 84 64 79.9706 64 75C64 70.0294 59.9706 66 55 66C50.0294 66 46 70.0294 46 75C46 79.9706 50.0294 84 55 84Z" fill="#D14F4F"/>
    <path d="M58 77.8594L52 71.8594" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M52 77.8594L58 71.8594" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    
</template>

<script>
export default {};
</script>

<style>
</style>