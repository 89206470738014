<template>
  <div class="preview-error">
    <PreviewErrorSm class="preview-error__img preview-error__img--sm" />
  </div>
</template>

<script>
import PreviewErrorSm from "../illustrations/preview-error-icon-sm.vue";

export default {
  props: {
    isSmall: Boolean,
  },
  components: {
    PreviewErrorSm
  },
};
</script>

<style lang="scss" scoped>
.preview-error {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: var(--v-error-lighten5);
  overflow: hidden;
  border-radius: 100%;
  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    opacity: 0.15;
    height: 100%;
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  &__img {
    height: 32px;
    width: 32px;
    position: relative;
    z-index: 2;
    &--sm {
      height: 20px;
      width: 20px;
    }
  }
}
</style>