<template>
  <v-btn
    href="mailto:feedback@panelalpha.com?subject=PanelAlpha Demo Feedback"
    :x-large="!$vuetify.breakpoint.mobile"
    color="warning"
    class=""
    rounded
  >
    <v-icon>$mail</v-icon>
    <span class="font-weight-600">Send Us Your Feedback</span>
  </v-btn>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.v-btn {
  position: fixed;
  bottom: 32px;
  right: 26px;
  box-shadow: 0px 8px 48px 6px rgba(215, 120, 8, 0.27);
  z-index: 99;
  @media (max-width: map-get($grid-breakpoints, md)) {
    left: 24px;
    right: unset;
    bottom: 16px;
  }
  @media (max-width: 460px) {
    left: unset;
    right: 24px;
    bottom: 64px;
  }
  filter: drop-shadow(0px 8px 32px rgba(244, 138, 12, 0.16));
  &:hover,
  &:focus {
    background: map-get($warning, lighten1);
    box-shadow: 0px 8px 48px 6px rgba(255, 138, 0, 0.4);
    filter: drop-shadow(0px 8px 32px rgba(244, 138, 12, 0.16));
  }
}
</style>