<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.44233 1.7605C1.43722 1.75355 1.43193 1.74674 1.42647 1.74006C1.38797 1.69304 1.33829 1.59056 1.31389 1.42869C1.29035 1.27247 1.29628 1.09713 1.33292 0.93734C1.41394 0.583974 1.56857 0.510014 1.6721 0.510014L14.323 0.510012C14.4277 0.510012 14.5834 0.585519 14.6657 0.938988C14.703 1.09884 14.7094 1.27407 14.6864 1.43C14.6624 1.59161 14.6131 1.69353 14.575 1.74006C14.5692 1.74716 14.5636 1.7544 14.5582 1.7618L10.2087 7.71505C10.0803 7.89074 10.0803 8.12928 10.2087 8.30498L14.5582 14.2582C14.5636 14.2656 14.5692 14.2729 14.575 14.28C14.5921 14.3009 14.6298 14.3726 14.6316 14.5228C14.6333 14.6664 14.6004 14.8383 14.5322 15.0041C14.3818 15.3695 14.1602 15.51 14.0067 15.51L1.67204 15.51C1.5685 15.51 1.41387 15.436 1.33285 15.0827C1.29621 14.9229 1.29029 14.7476 1.31383 14.5913C1.33822 14.4295 1.38791 14.327 1.4264 14.28C1.43186 14.2733 1.43715 14.2665 1.44226 14.2595L5.82135 8.30628C5.95099 8.13004 5.95099 7.88998 5.82135 7.71374L1.44233 1.7605Z"
      stroke="currentColor"
      stroke-linejoin="round"
    />
    <path
      d="M8.78087 6.53392C8.38054 7.03433 7.61946 7.03433 7.21913 6.53392L4 2.51001L12 2.51001L8.78087 6.53392Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>