<template>
  <v-row
    class="data-iterator-footer my-3 mx-4"
    align="center"
    justify="center"   
    v-if="!(numberOfPages === 1 && itemsPerPage === possibleItemsPerPage[listStyle][0])"
  >
    <v-pagination
      v-model="pageProp"
      :length="numberOfPages ? numberOfPages : 1"
      :total-visible="9"
      next-icon="$paginationright"
      prev-icon="$paginationleft"
      elevation="0"
      outlined
    >

    </v-pagination>

    <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
    <div class="data-iterator-page-number" v-if="!$vuetify.breakpoint.mobile">
      <span>{{ perPageLabel || $t('table.footer.record') }}</span>
      <v-btn
        v-for="(item, index) in possibleItemsPerPage[listStyle]"
        :key="index"
        elevation="0"
        @click="updateItemsPerPage(possibleItemsPerPage[listStyle][index])"
        :class="
          pageNumberIsActive(possibleItemsPerPage[listStyle][index])
            ? 'active'
            : ''
        "
      >
        {{ possibleItemsPerPage[listStyle][index] }}
      </v-btn>
    </div>
  </v-row>
</template>

<script>
export default {
  props: {
    page: Number,
    numberOfPages: Number,
    possibleItemsPerPage: Array,
    itemsPerPage: Number,
    listStyle: Number,
    itemsTotal: Number,
    perPageLabel: {
      type: String,
      default: ""
    }
  },
  computed: {
    pageProp: {
      get: function () {
        return this.page;
      },
      set: function (newValue) {
        this.$emit("update:page", newValue);
      },
    },
  },
  methods: {
    updateItemsPerPage: function (target) {
      if (target === "All") {
        target = 99;
      }
      this.$emit("update:itemsPerPage", target);
    },
    pageNumberIsActive: function (target) {
      if (target === "All") {
        target = 99;
      }
      return this.itemsPerPage === target;
    },
  },
};
</script>

<style lang="scss" scoped >
.v-data-table .data-iterator-footer {
  margin: 0px 16px;
}
.data-iterator-footer {
  margin-top: 32px;
}
.v-btn {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.data-iterator-footer::v-deep {
  .v-pagination {
    .v-pagination__navigation {
      border: none !important;
      box-shadow: none !important;
      margin: 0;
      margin-right: 2px;
    }
    .v-pagination__item {
      box-shadow: none;
      font-size: $font-size-base;
      line-height: $line-height-base;
      font-weight: $font-weight-light;
      // border: 1px solid map-get($gray, base);
      margin: 0;
      margin-right: 2px;
    }
  }
  .data-iterator-page-number {
    > span {
      margin-right: 10px;
    }
    .v-btn {
      padding: 0;
      min-width: 32px;
      height: 34px;
      box-shadow: none;
      // border: 1px solid map-get($gray, base);
      background: transparent;

      text-transform: capitalize;
      margin-left: 2px;
      .v-btn__content {
        font-size: $font-size-sm;
        line-height: $line-height-sm;
        font-weight: $font-weight-normal;
      }

      &.active {
        color: white;
        background: map-get($primary, base);
        border-color: map-get($primary, base);
      }
    }
  }
}

</style>