<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6847_205540)">
      <path
        d="M11.9994 0.00168037C5.37236 0.00168037 0 5.37404 0 12.0011C0 18.4364 5.0663 23.6886 11.428 23.9866C11.6178 23.9955 11.808 24.0006 11.9996 24.0006C15.0718 24.0006 17.8736 22.8454 19.9965 20.9469C18.59 21.8797 16.9455 22.4165 15.1881 22.4165C12.3305 22.4165 9.77125 20.9988 8.05003 18.7633C6.72317 17.1971 5.86379 14.8819 5.80524 12.2836V11.7185C5.86375 9.12027 6.72313 6.80458 8.04969 5.23872C9.77116 3.00321 12.3303 1.5856 15.1877 1.5856C16.9456 1.5856 18.5904 2.12232 19.9971 3.05574C17.8845 1.16592 15.0997 0.0130882 12.0446 0.00174332C12.0293 0.00174332 12.0144 0.00114822 11.9992 0.00114822L11.9994 0.00168037Z"
        fill="url(#paint0_linear_6847_205540)"
      />
      <path
        d="M8.0498 5.23945C9.15099 3.93947 10.5731 3.15557 12.1268 3.15557C15.6202 3.15557 18.4516 7.11615 18.4516 12.0017C18.4516 16.8872 15.62 20.8478 12.1268 20.8478C10.5731 20.8478 9.15099 20.0642 8.0501 18.7642C9.77128 20.9997 12.3303 22.4174 15.1881 22.4174C16.9457 22.4174 18.5902 21.8806 19.9966 20.9478C22.4527 18.7505 23.9993 15.5568 23.9993 12.0018C23.9993 8.44716 22.453 5.25363 19.9975 3.05659C18.5906 2.12317 16.9459 1.58645 15.1882 1.58645C12.3306 1.58645 9.77102 3.00393 8.0498 5.23945Z"
        fill="url(#paint1_linear_6847_205540)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_6847_205540"
        x1="9.99882"
        y1="0.392831"
        x2="9.99882"
        y2="23.6517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF1B2D" />
        <stop offset="0.3" stop-color="#FF1B2D" />
        <stop offset="0.614" stop-color="#FF1B2D" />
        <stop offset="1" stop-color="#A70014" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6847_205540"
        x1="16.0243"
        y1="1.76399"
        x2="16.0243"
        y2="22.3352"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9C0000" />
        <stop offset="0.7" stop-color="#FF4B4B" />
        <stop offset="1" stop-color="#FF4B4B" />
      </linearGradient>
      <clipPath id="clip0_6847_205540">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>