import axios from "axios";
import Api from "@/apis/Api";
import Helper from "@/apis/Helper";

export const fetchInstanceLogs = async (appId, page, perPage, sortBy, sortDesc = false, searchTerm = '') => {

    let sortDir = sortDesc ? 'desc' : 'asc';
    let url = `?page=${page}&per_page=${perPage}&sort_by=${sortBy}&sort_dir=${sortDir}`
    if (searchTerm) {
        url += `&search_term=${searchTerm}`;
    }

    try {
        let meta = {};
        let records = await Api.get(`/instances/${appId}/logs${url}`).then(response => {
            meta = response.data.meta;
            return response.data.data;
        });

        return { data: records, meta: meta, error: null };

    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const fetchActiveInstanceDetails = async (subinstance) => {

    // temp
    subinstance = 'live';

    try {
        const data = await axios.get(`/data/instances/1/${subinstance}/details.json`);
        return { data: data.data, error: null };
    } catch (error) {
        return { data: null, error }
    }
}

export const fetchAutoBackupsSettings = async function (subinstance) {
    try {
        const { data: { data } } = await Api.get(`/instances/${subinstance}/wordpress/automatic-backup`)

        return data
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const submitAutoBackupsSettings = async function (subinstance, settings) {
    try {
        const newSettings = {
            ...settings,
            cron_day_of_week: settings.cron_day_of_week === '*' ? '0' : settings.cron_day_of_week,
            cron_day_of_month: settings.cron_day_of_month === '*' ? '1' : settings.cron_day_of_month
        };

        const { data } = await Api.put(`/instances/${subinstance}/wordpress/automatic-backup`, newSettings)

        return data
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const fetchActiveInstanceBackups = async (subinstance) => {
    try {
        let backups = await Api.get(`/instances/${subinstance}/wordpress/backups`).then((response) => {
            let backups = [];
            for (const backup of response.data.data) {
                backups.push({
                    id: backup.id,
                    name: backup.name,
                    date: `${backup.date}000`,
                    size: Helper.formatBytes(backup.size),
                    type: backup.type.charAt(0).toUpperCase() + backup.type.slice(1),
                    version: backup.version,
                    note: backup.note,
                    async_status: backup.async_status ? backup.async_status : {},
                });
            }
            return backups;
        });

        return { data: backups, error: null };
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const fetchActiveInstancePlugins = async (subinstance) => {
    try {
        let plugins = await Api.get(`/instances/${subinstance}/wordpress/plugins`).then((response) => {
            return response.data.data;
        });
        return { data: plugins, error: null }
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const fetchNewPlugins = async (subinstance, searchTerm) => {
    try {
        if (!searchTerm) {
            return { data: [], error: null }
        }
        let plugins = await Api.get(`/instances/${subinstance}/wordpress/plugins/new/search/${searchTerm}`).then((response) => {
            return response.data.data;
        });
        return { data: plugins, error: null }
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const fetchNewPluginsFromPackages = async (subinstance) => {
    try {
        let plugins = await Api.get(`/instances/${subinstance}/wordpress/plugins/new/featured`).then((response) => {
            return response.data.data;
        });
        return { data: plugins, error: null }
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const fetchActiveInstanceThemes = async (subinstance) => {
    try {
        let apiUrl = Api.defaults.baseURL;

        let themes = await Api.get(`/instances/${subinstance}/wordpress/themes`).then((response) => {
            return response.data.data.map(theme => {
                theme.img = `${apiUrl}/instances/${subinstance}/wordpress/themes/${theme.name}/screenshot`;
                return theme;
            });
        });
        return { data: themes, error: null }
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const fetchNewThemes = async (subinstance, searchTerm) => {
    try {
        if (!searchTerm) {
            return { data: [], error: null }
        }
        let themes = await Api.get(`/instances/${subinstance}/wordpress/themes/new/search/${searchTerm}`).then((response) => {
            return response.data.data;
        });
        return { data: themes, error: null }
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const fetchNewThemesFromPackages = async (subinstance) => {
    try {
        let plugins = await Api.get(`/instances/${subinstance}/wordpress/themes/new/featured`).then((response) => {
            return response.data.data;
        });
        return { data: plugins, error: null }
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const fetchActiveInstanceConfig = async (subinstance) => {
    try {
        // const data = await axios.get(`/data/instances/1/${subisntance}/configs.json`)

        let configs = await Api.get(`/instances/${subinstance}/wordpress/config`).then((response) => {
            return response.data.data;
        });

        return { data: configs, error: null }
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const addLabelToSubinstance = async (label, instance) => {
    try {
        await Api.post(`/instances/${instance}/labels`, label);
        return { data: true, error: null }
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const deleteLabelFromUserLabels = async (label) => {
    try {
        await Api.delete(`/labels`, { data: label });
        return { data: true, error: null }
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const removeLabelFromSubinstance = async (label, instance) => {
    try {
        const response = await Api.delete(`/instances/${instance}/labels`, { data: label });
        return { data: response.data, error: null }
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}

export const addNewLabel = async (label) => {
    try {
        await Api.post('/labels', label);
        return { data: true, error: null }
    } catch (error) {
        return { data: null, error: Api.getErrorMessage(error) }
    }
}