var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{ref:"dialog",attrs:{"hide-overlay":"","overlay-opacity":0,"transition":false,"persistent":"","width":"561px","value":_vm.isOpen ? true : false,"absolute":""}},[_c('div',{staticClass:"card-overlay",on:{"click":function($event){return _vm.$refs.dialog.animateClick()}}}),(_vm.isOpen)?_c('v-card',{staticStyle:{"width":"561px"}},[[_c('v-card-title',{staticClass:"pb-0 flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-light p-1 base--text"},[_vm._v(" "+_vm._s(_vm.$t('heading.onboarding.import.url.title'))+" ")]),_c('h4',{},[_vm._v(_vm._s(_vm.$t('heading.onboarding.import.url.subtitle')))])]),_c('v-btn',{staticClass:"close-model",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.$emit('removeModal')}}},[_c('v-icon',[_vm._v("$close")])],1)],1),_c('v-card-text',{staticClass:"px-10 pt-8",attrs:{"height":"800px"}},[_c('h6',[_vm._v(_vm._s(_vm.$t("heading.onboarding.chooseProduct.product.title")))]),_c('p',{staticClass:"p-3 mt-2"},[_vm._v(" "+_vm._s(_vm.$t("heading.onboarding.chooseProduct.product.subtitle"))+" ")]),_c('v-form',{ref:"form"},[_c('v-select',{staticClass:"v-input--lg",attrs:{"outlined":"","items":_vm.services,"hide-details":"","return-object":"","validate-on-blur":"","item-value":"id","menu-props":{
              offsetY: true,
              nudgeBottom: '8px',
              closeOnContentClick: false,
              contentClass:
                'custom-dropdown-select custom-dropdown-select--onboarding',
            }},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.plan_name)+" ("+_vm._s(data.item.instance_count)+"/"+_vm._s(data.item.instance_limit)+" Instances)"),(data.item.hosting_accounts.length)?[_vm._v(" - "+_vm._s(data.item.hosting_accounts[0].domain))]:_vm._e()]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.plan_name)+" ("+_vm._s(data.item.instance_count)+"/"+_vm._s(data.item.instance_limit)+" Instances)"),(data.item.hosting_accounts.length)?[_vm._v(" - "+_vm._s(data.item.hosting_accounts[0].domain))]:_vm._e()]}}],null,false,3415840508),model:{value:(_vm.selectedService),callback:function ($$v) {_vm.selectedService=$$v},expression:"selectedService"}})],1)],1),_c('v-card-actions',{staticClass:"d-flex flex-column pb-4 px-10 pt-2"},[_c('v-btn',{attrs:{"x-large":"","elevation":"0","color":"primary","block":""},on:{"click":function($event){return _vm.$emit('service-selected', _vm.selectedService)}}},[[_vm._v(" "+_vm._s(_vm.$t("button.continue"))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"24"}},[_vm._v("$arrowright")])]],2),_c('v-btn',{staticClass:"mx-0 mt-2",attrs:{"x-large":"","elevation":"0","color":"gray","text":"","block":""},on:{"click":function($event){return _vm.$emit('goBack')}}},[_c('span',{staticClass:"p-1 font-weight-light gray--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")])])],1)]],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }