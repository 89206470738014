<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.8909 14.909C18.8909 15.7177 18.2743 16.3876 17.4875 16.4726H17.309H2.79087C1.92497 16.4726 1.21814 15.7658 1.21814 14.8999V5.02716C1.21814 4.19762 1.8977 3.51807 2.72723 3.51807H6.42723C6.71118 3.51807 6.96481 3.62441 7.15649 3.81608L7.15648 3.81609L7.15991 3.81949L8.10537 4.75585L8.18162 4.83138L8.27217 4.889L8.80905 4.04534C8.27217 4.889 8.27229 4.88908 8.27241 4.88915L8.27265 4.88931L8.27315 4.88962L8.27417 4.89027L8.27636 4.89165L8.28132 4.89476L8.29352 4.90227C8.3026 4.9078 8.31388 4.91452 8.32697 4.92206C8.35229 4.93663 8.38865 4.95666 8.43225 4.97771C8.43351 4.97832 8.435 4.97904 8.43671 4.97987C8.48408 5.00298 8.70145 5.10898 8.97269 5.10898H17.3181C18.184 5.10898 18.8909 5.81581 18.8909 6.6817V14.909Z" stroke="currentColor" stroke-width="2"/>
    <path d="M3.7 13.66C3.45 13.66 3.25 13.46 3.25 13.21V9.6C3.25 8.85 3.86 8.25 4.6 8.25H6.41C6.66 8.25 6.86 8.45 6.86 8.7C6.86 8.95 6.66 9.15 6.41 9.15H4.6C4.35 9.15 4.15 9.35 4.15 9.6V13.21C4.15 13.46 3.95 13.66 3.7 13.66Z" fill="currentColor"/>
    <path d="M3.7 13.91C3.31 13.91 3 13.6 3 13.21V9.6C3 8.72 3.72 8 4.6 8H6.4C6.79 8 7.1 8.31 7.1 8.7C7.1 9.09 6.79 9.4 6.4 9.4H4.6C4.49 9.4 4.4 9.49 4.4 9.6V13.21C4.4 13.6 4.09 13.91 3.7 13.91ZM4.6 8.49C3.99 8.49 3.5 8.99 3.5 9.59V13.2C3.5 13.31 3.59 13.4 3.7 13.4C3.81 13.4 3.9 13.31 3.9 13.2V9.59C3.9 9.2 4.21 8.89 4.6 8.89H6.4C6.51 8.89 6.6 8.8 6.6 8.69C6.6 8.58 6.51 8.49 6.4 8.49H4.6Z" fill="currentColor"/>
    <path d="M13.6301 13.66C13.3801 13.66 13.1801 13.46 13.1801 13.21V8.7C13.1801 8.45 13.3801 8.25 13.6301 8.25H14.9801C15.9801 8.25 16.7901 9.06 16.7901 10.06C16.7901 11.06 15.9801 11.87 14.9801 11.87H14.0801V13.22C14.0801 13.47 13.8801 13.67 13.6301 13.67V13.66ZM14.0801 10.95H14.9801C15.4801 10.95 15.8801 10.55 15.8801 10.05C15.8801 9.55 15.4801 9.15 14.9801 9.15H14.0801V10.96V10.95Z" fill="currentColor"/>
    <path d="M13.6301 13.91C13.2401 13.91 12.9301 13.6 12.9301 13.21V8.7C12.9301 8.31 13.2401 8 13.6301 8H14.9801C16.1101 8 17.0301 8.92 17.0301 10.06C17.0301 11.2 16.1101 12.12 14.9801 12.12H14.3301V13.22C14.3301 13.61 14.0201 13.92 13.6301 13.92V13.91ZM13.6301 8.49C13.5201 8.49 13.4301 8.58 13.4301 8.69V13.2C13.4301 13.31 13.5201 13.4 13.6301 13.4C13.7401 13.4 13.8301 13.31 13.8301 13.2V11.6H14.9801C15.8401 11.6 16.5301 10.9 16.5301 10.04C16.5301 9.18 15.8301 8.48 14.9801 8.48H13.6301V8.49ZM14.9801 11.2H13.8301V8.89H14.9801C15.6201 8.89 16.1301 9.41 16.1301 10.04C16.1301 10.67 15.6101 11.19 14.9801 11.19V11.2ZM14.3301 10.7H14.9801C15.3401 10.7 15.6301 10.41 15.6301 10.05C15.6301 9.69 15.3401 9.4 14.9801 9.4H14.3301V10.71V10.7Z" fill="currentColor"/>
    <path d="M6.41 11.85H3.7C3.45 11.85 3.25 11.65 3.25 11.4C3.25 11.15 3.45 10.95 3.7 10.95H6.41C6.66 10.95 6.86 11.15 6.86 11.4C6.86 11.65 6.66 11.85 6.41 11.85Z" fill="currentColor"/>
    <path d="M6.41 12.1H3.7C3.31 12.1 3 11.79 3 11.4C3 11.01 3.31 10.7 3.7 10.7H6.41C6.8 10.7 7.11 11.01 7.11 11.4C7.11 11.79 6.8 12.1 6.41 12.1ZM3.7 11.2C3.59 11.2 3.5 11.29 3.5 11.4C3.5 11.51 3.59 11.6 3.7 11.6H6.41C6.52 11.6 6.61 11.51 6.61 11.4C6.61 11.29 6.52 11.2 6.41 11.2H3.7Z" fill="currentColor"/>
    <path d="M10.0199 13.66C9.76995 13.66 9.56995 13.46 9.56995 13.21V8.7C9.56995 8.45 9.76995 8.25 10.0199 8.25C10.2699 8.25 10.4699 8.45 10.4699 8.7V13.21C10.4699 13.46 10.2699 13.66 10.0199 13.66Z" fill="currentColor"/>
    <path d="M10.0199 13.91C9.62995 13.91 9.31995 13.6 9.31995 13.21V8.7C9.31995 8.31 9.62995 8 10.0199 8C10.4099 8 10.7199 8.31 10.7199 8.7V13.21C10.7199 13.6 10.4099 13.91 10.0199 13.91ZM10.0199 8.49C9.90995 8.49 9.81995 8.58 9.81995 8.69V13.2C9.81995 13.31 9.90995 13.4 10.0199 13.4C10.1299 13.4 10.2199 13.31 10.2199 13.2V8.69C10.2199 8.58 10.1299 8.49 10.0199 8.49Z" fill="currentColor"/>
    <path d="M11.83 9.15H8.22002C7.97002 9.15 7.77002 8.95 7.77002 8.7C7.77002 8.45 7.97002 8.25 8.22002 8.25H11.83C12.08 8.25 12.28 8.45 12.28 8.7C12.28 8.95 12.08 9.15 11.83 9.15Z" fill="currentColor"/>
    <path d="M11.82 9.4H8.21001C7.82001 9.4 7.51001 9.09 7.51001 8.7C7.51001 8.31 7.82001 8 8.21001 8H11.82C12.21 8 12.52 8.31 12.52 8.7C12.52 9.09 12.21 9.4 11.82 9.4ZM8.21001 8.5C8.10001 8.5 8.01001 8.59 8.01001 8.7C8.01001 8.81 8.10001 8.9 8.21001 8.9H11.82C11.93 8.9 12.02 8.81 12.02 8.7C12.02 8.59 11.93 8.5 11.82 8.5H8.21001Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>