<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.04894 2.94707C9.3483 2.02576 10.6517 2.02576 10.9511 2.94707L12.0206 6.23887C12.1545 6.65089 12.5385 6.92985 12.9717 6.92985H16.4329C17.4016 6.92985 17.8044 8.16947 17.0207 8.73887L14.2205 10.7733C13.87 11.028 13.7234 11.4793 13.8572 11.8913L14.9268 15.1831C15.2261 16.1045 14.1717 16.8706 13.388 16.3012L10.5878 14.2667C10.2373 14.0121 9.7627 14.0121 9.41221 14.2667L6.61204 16.3012C5.82833 16.8706 4.77385 16.1044 5.0732 15.1831L6.14277 11.8913C6.27665 11.4793 6.12999 11.028 5.7795 10.7733L2.97933 8.73887C2.19562 8.16946 2.59839 6.92985 3.56712 6.92985H7.02832C7.46154 6.92985 7.8455 6.65089 7.97937 6.23887L9.04894 2.94707Z"
      stroke="#EED000"
      stroke-width="2"
    />
    <mask
      id="mask0_7366_16067"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="18"
      height="17"
    >
      <path
        d="M9.04894 2.94707C9.3483 2.02576 10.6517 2.02576 10.9511 2.94707L12.0206 6.23887C12.1545 6.65089 12.5385 6.92985 12.9717 6.92985H16.4329C17.4016 6.92985 17.8044 8.16947 17.0207 8.73887L14.2205 10.7733C13.87 11.028 13.7234 11.4793 13.8572 11.8913L14.9268 15.1831C15.2261 16.1045 14.1717 16.8706 13.388 16.3012L10.5878 14.2667C10.2373 14.0121 9.7627 14.0121 9.41221 14.2667L6.61204 16.3012C5.82833 16.8706 4.77385 16.1044 5.0732 15.1831L6.14277 11.8913C6.27665 11.4793 6.12999 11.028 5.7795 10.7733L2.97933 8.73887C2.19562 8.16946 2.59839 6.92985 3.56712 6.92985H7.02832C7.46154 6.92985 7.8455 6.65089 7.97937 6.23887L9.04894 2.94707Z"
        stroke="#FADD77"
        stroke-width="2"
      />
    </mask>
    <g mask="url(#mask0_7366_16067)">
      <rect :x="20 * $parent.$attrs.percent" y="0.0200195" :width="`${(1 - $parent.$attrs.percent) * 100}%`" height="20" fill="#EEEEF4" />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>