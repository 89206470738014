<template>
  <div class="assign-existing-label">
    <div class="px-3 py-3 heading--text d-flex justify-space-between">
      <div class="p-2 font-weight-bold py-1">{{ $t('heading.instance.tag.assignExisting.title') }}</div>
      <div class="btn-close" v-ripple @click="closeMenu()">
        <v-icon size="16">$close</v-icon>
      </div>
    </div>

    <v-divider />

    <div v-if="instanceLabels.length > 0" class="px-4 pt-4 pb-2 labels-list">
      <labels-list
        :maxLabelsDisplayed="99"
        :labels="instanceLabels"
        :removable="true"
        :chips="true"
        :loading="isLoading"
        small
        containerClass="ml-0"
        v-on="$listeners"
      />
    </div>
    <div v-else class="px-4 py-4 labels-list">
      <span
        class="gray--text text--darken-1 text-center mb-0 p-4 font-weight-600"
        v-if="!isLoading"
      >
        {{ $t('message.tag.noAssigned') }}
      </span>
      <span v-else>
        <Loader noMargins size="16" />
      </span>
    </div>

    <v-divider />

    <div class="d-flex">
      <v-autocomplete
        :label="$t('form.placeholder.tag.assign')"
        hide-details
        solo
        class="pt-0 mt-0 custom-input"
        :items="availableLabels"
        nudge-bottom="24px"
        :elevation="0"
        flat
        :menu-props="{
          contentClass: 'label-autocomplete',
        }"
        item-text="title"
        item-value="title"
        v-model="input"
        @update:search-input="input = ''"
      >
        <template v-slot:append>
          <v-icon size="16">$chevrondown</v-icon>
        </template>
        <template v-slot:prepend-inner>
          <v-icon size="20">$search</v-icon>
        </template>

        <template v-slot:no-data>
          <div class="text-center px-4 py-4">
            <i18next :translation="$t('message.tag.noTags')">
              <template #link>
                <a @click="changeView()" class="info--text">{{ $t('button.tag.add') }}</a>
              </template>
            </i18next>
          </div>
        </template>

        <template v-slot:item="{ item }">
          <div
            @click="$emit('assign-label', item)"
            class="d-flex justify-space-between w-100 instance-label-list"
          >
            <label-chip :item="item" xsmall />

            <v-tooltip
              transition="custom-tooltip"
              open-delay="150"
              bottom
              v-if="item.in_use"
              max-width="148px"
              content-class="instance-label-list__tooltip"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-on="on"
                  v-bind="attrs"
                  class="
                    instance-label-list__remove
                    instance-label-list__remove--disabled
                  "
                  v-on:click.stop
                >
                  {{ $t('button.remove') }}
                </div>
              </template>
              <span class="p-4 text-center"
                >{{ $t('tooltip.label.remove') }}</span
              >
            </v-tooltip>
            <div
              v-else
              class="instance-label-list__remove"
              v-on:click.stop="$emit('delete-label', item)"
            >
              {{ $t('button.remove') }}
            </div>
          </div>
        </template>
      </v-autocomplete>
      <v-btn class="custom-button" @click="changeView()">{{ $t('button.addNew') }}</v-btn>
    </div>
  </div>
</template>

<script>
import LabelChip from "../labels/LabelChip.vue";
import LabelMenuMixin from "../../mixins/LabelMenuMixin";
import LabelsList from "../labels/LabelsList.vue";
import Loader from "../Loader.vue";

export default {
  components: {
    LabelsList,
    LabelChip,
    Loader,
  },
  mixins: [LabelMenuMixin],
  data: function () {
    return {
      input: "",
    };
  },
  props: {
    instanceLabels: Array,
    availableLabels: Array,
    instance: Object,
    isLoading: Boolean,
  },

  methods: {
    changeView: function () {
      this.$emit("changeView");
    },
    clearInput: function () {
      this.input = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-close {
  height: 32px;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-button,
.custom-input {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  background: transparent !important;
  height: 56px !important;
}
.custom-button.v-btn::v-deep {
  .v-btn__content {
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: map-get($text, headings);
  }
}

.custom-input::v-deep {
  padding: 0px;
  border-right: 1px solid map-get($gray, lighten2) !important;
  .label-autocomplete {
    margin-top: 16px;
  }
  &.v-select--is-menu-active {
    .v-input__prepend-inner {
      .v-icon {
        color: map-get($primary, base);
      }
    }
    .v-input__append-inner {
      .v-icon {
        transform: rotate(180deg);
        transform-origin: center;
        color: map-get($primary, base);
      }
    }
  }

  .v-label {
    top: 18px !important;
    transform: none !important;
  }
  input {
    max-height: 56px !important;
    height: 56px !important;
    margin-bottom: -10px;
  }

  .v-input__prepend-inner {
    height: 100%;
    display: flex;
    margin-top: 10px !important;
    margin-left: 4px;
  }
  .v-input__append-inner {
    margin-top: 10px !important;
  }

  .v-input__control {
    margin-top: 0px;
  }
  .v-input__slot {
    &::before,
    &::after {
      display: none;
    }
  }
}

.v-input::v-deep .v-input__control .v-input__slot {
  .v-input__prepend-inner,
  .v-input__append-inner {
    margin-top: 9px !important;
  }
}
</style>
