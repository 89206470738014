<template>
  <div class="add-new-label">
    <div class="px-3 py-3 heading--text d-flex justify-space-between">
      <div class="p-2 font-weight-bold py-1">{{ $t('heading.instance.tag.add.title') }}</div>
      <div class="btn-close" v-ripple @click="closeMenu()">
        <v-icon size="16">$close</v-icon>
      </div>
    </div>

    <v-divider />

    <div class="px-4 py-4">
      <v-text-field
        solo
        class="add-new-tag-input mb-2 v-input--xsm"
        :label="$t('form.placeholder.tag.add')"
        hide-details=""
        v-model="labelTitle"
        flat
        :messages="error ? error : undefined"
        :error="error ? true : undefined"
      />

      <div class="color-swatch-container">
        <color-swatch
          v-for="(item, index) in colors"
          :key="index"
          :color="item"
          :active="activeColor === item"
          @update:activeColor="activeColor = item"
        />
      </div>
      <p v-if="errorMsg || error" class="p-5 mb-1 mt-3 error--text">
        {{ errorMsg ? errorMsg : "" }}
        {{ error ? error : "" }}
      </p>
    </div>

    <v-divider />

    <div class="d-flex px-4 py-4">
      <v-btn color="primary" elevation="0" small @click="addNewLabel()">
        <span class="font-weight-bold">{{ $t('button.label.add') }}</span>
      </v-btn>
      <v-btn
        class="ml-2 gray--text"
        outlined
        elevation="0"
        small
        @click="cancelNewLabel()"
      >
        <span class="font-weight-bold button-cancel">{{ $t('button.cancel') }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import LabelMenuMixin from "../../mixins/LabelMenuMixin";
import ColorSwatch from "../ColorSwatch.vue";

export default {
  components: { ColorSwatch },
  mixins: [LabelMenuMixin],
  props: {
    instanceLabels: Array,
    availableLabels: Array,
    error: String,
  },
  data: function () {
    return {
      errorMsg: "",
      labelTitle: "",
      activeColor: "",
      colors: [
        "#EB6464",
        "#EB64AD",
        "#EB64D5",
        "#D064EB",
        "#9F64EB",
        "#6764EB",
        "#448ED6",
        "#44B3D6",
        "#44CDD6",
        "#44D6BB",
        "#44D690",
        "#51CC56",
        "#07C07E",
        "#91D23E",
        "#C9BC44",
        "#C5944B",
        "#EE8F49",
        "#D72E23",
      ],
    };
  },
  methods: {
    cancelNewLabel: function () {
      this.$emit("changeView");
    },
    addNewLabel: async function () {
      this.errorMsg = "";

      if (this.labelTitle.length < 3) {
        this.errorMsg = this.$t('form.validation.minLength', {
          field: this.$t('form.label.tagTitle'),
          length: 3
        });
      } else if (this.labelTitle.length > 18) {
        this.errorMsg = this.$t('form.validation.maxLength', {
          field: this.$t('form.label.tagTitle'),
          length: 18
        });
      } else if (!this.activeColor) {
        this.errorMsg = this.$t('form.validation.color', {
          field: this.$t('form.label.tag'),
        });
      }
      if (!this.errorMsg) {
        this.$emit("create-label", {
          title: this.labelTitle,
          color: this.activeColor,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  height: 50px;
}
.btn-close {
  height: 32px;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  .v-icon {
  }
}
.add-new-tag-input {
  outline: 1px solid map-get($gray, base);
  border: 2px solid transparent;

  transition: outline 0.24s ease, border 0.24s ease;
  &.v-input--is-focused {
    outline: none;
    border: 2px solid map-get($primary, base);
  }
}
.color-swatch-container {
  display: flex;
  flex-wrap: wrap;
  align-items: space-between;
  height: 68px;
}

.custom-button,
.custom-input {
  border-radius: 0 !important;
  background: transparent !important;
  height: 56px !important;
}

.custom-input::v-deep {
  padding: 0 16px;
  // border-right: 1px solid map-get($gray, lighten2) !important;

  .v-input__append-inner {
    margin-top: 6px !important;
  }
  .v-input__control {
    margin-top: 10px;
  }
  .v-input__slot {
    &::before,
    &::after {
      display: none;
    }
  }
}

.v-input.v-text-field.v-input.v-input--xsm::v-deep {
  .v-input__control,
  .v-input__slot {
    min-height: 38px;
    max-height: 38px;
  }
}

.button-cancel {
  color: var(--v-text-darken2) !important;
}
</style>