import Api from "@/apis/Api";

export default {
	methods: {
		wpAdminSsoRedirect(application) {
			setTimeout(() => {
				Api.post(
					`/instances/${application.id}/sso-url`,
					{user: 1}
				).then(response => {
					//window.open(response.data.data.sso_url, '_blank');
					window.location.href = response.data.data.sso_url;
				})
					.catch((error) => {
						this.$store.dispatch("addAlert", {
							success: false,
							errorMessage: Api.getErrorMessage(error),
						});
					})
					.finally(() => {
						this.installationCompleted = true;
						this.installationError = false;
						this.installedInstance = application;
					})
			}, 5000);
		},
	}
}