var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload-img",class:[
      _vm.fileIsLoaded || _vm.fileData ? 'upload-img--done' : '',
      _vm.hovering ? 'upload-img--hover' : '',
    ]},[_c('div',{staticClass:"upload-img__content"},[_c('input',{ref:"fileInput",staticClass:"file-input",attrs:{"type":"file","accepts":"image/*"},on:{"change":_vm.uploadFile}}),(!_vm.fileIsLoaded && !_vm.fileData)?_c('div',{staticClass:"upload-img__icon"},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v("$backup")])],1):_vm._e(),(_vm.fileData)?_c('div',{staticClass:"upload-img__text",style:(_vm.type === 'light' && _vm.$vuetify.theme.dark
          ? 'background-color: #fff'
          : _vm.type === 'dark' && !_vm.$vuetify.theme.dark
          ? 'background-color: var(--v-secondary-darken2)'
          : '')},[(!_vm.isSvg)?_c('img',{staticClass:"upload-img__preview-img",attrs:{"src":_vm.fileData,"alt":""}}):_c('div',{staticClass:"upload-img__preview-svg",domProps:{"innerHTML":_vm._s(_vm.fileData)}})]):_vm._e(),(_vm.fileIsLoaded || _vm.fileData)?_c('div',{staticClass:"upload-img__control-btns"},[_c('v-btn',{attrs:{"x-small":"","text":""},on:{"click":_vm.replaceFile}},[_c('v-icon',{staticClass:"mr-0",attrs:{"size":"16","stroke":"2"}},[_vm._v("$restore")])],1),_c('v-btn',{attrs:{"x-small":"","text":""},on:{"click":_vm.clearFile}},[_c('v-icon',{staticClass:"mr-0",attrs:{"size":"16"}},[_vm._v("$thrash")])],1)],1):_vm._e()]),_c('p',{staticClass:"mt-3 mb-0 text--darken2 font-weight-bold"},[_vm._v(_vm._s(_vm.text))])])
}
var staticRenderFns = []

export { render, staticRenderFns }