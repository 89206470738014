<template>
  <div class="ssl-indicator d-flex align-start">
    <v-tooltip
      open-delay="150"
      bottom
      z-index="99"
      offset-overflow
      nudge-bottom="12px"
      transition="custom-tooltip"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center">
          <loader
            v-on="on"
            v-bind="attrs"
            v-if="fetching"
            :noMargins="true"
            :size="16"
            color="primary"
          />
          <v-icon
            v-else
            v-on="on"
            v-bind="attrs"
            size="16"
            style="line-height: 0px"
            :color="icon.color"
          >
            {{ `$${icon.type}` }}
          </v-icon>
        </div>
      </template>
      <p class="mb-0">{{ tooltip.text }}</p>
      <ul class="mt-2" v-if="tooltip.listItems && tooltip.listItems.length">
        <li
          v-for="(item, index) of tooltip.listItems"
          :key="`tooltipListItem${index}`"
        >
          {{ item }}
        </li>
      </ul>
    </v-tooltip>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import Loader from "../Loader.vue";

export default {
  components: {
    Loader,
  },
  props: {
    instance: Object,
  },
  data() {
    return {
      fetching: true,
      dns: {
        resolved: false,
        ip_correct: false,
      },
      ssl: {
        certificate_installed: false,
        certificate: null,
      },
    };
  },
  computed: {
    tooltip() {
      if (this.fetching) {
        return { text: this.$t('tooltip.dnsIndicator.fetching', {
            url: this.instance.url
          })
        };
      }
      if (!this.dns.resolved || !this.dns.ip_correct) {
        if (this.instance.service_has_dns) {
          return {
            text: this.$t('tooltip.dnsIndicator.wrongNameservers'),
            listItems: this.instance.dns_nameservers,
          };
        }
        return {
          text: this.$t('tooltip.dnsIndicator.wrongRecords'),
          listItems: [
            `${this.instance.domain} A ${this.instance.host_ip_address}`,
            `www.${this.instance.domain} CNAME ${this.instance.domain}`,
          ],
        };
      }
      if (!this.ssl.certificate_installed) {
        return { text: this.$t('tooltip.dnsIndicator.notSsl') };
      }
      if (this.ssl.certificate && this.ssl.certificate.self_signed) {
        return {
          text: this.$t('tooltip.dnsIndicator.selfSigned'),
        };
      }
      if (this.ssl.certificate && !this.ssl.certificate.name_match) {
        return {
          text: this.$t('tooltip.dnsIndicator.sslNotMatched'),
        };
      }
      return { text: this.$t('tooltip.dnsIndicator.secure') };
    },
    icon() {
      if (!this.dns.resolved || !this.dns.ip_correct) {
        return { type: "alertcircle", color: "warning" };
      }
      if (!this.ssl.certificate_installed) {
        return { type: "unlock", color: "error" };
      }
      if (this.ssl.certificate && this.ssl.certificate.self_signed) {
        return { type: "lock", color: "error" };
      }
      if (this.ssl.certificate && !this.ssl.certificate.name_match) {
        return { type: "unlock", color: "error" };
      }
      return { type: "lock", color: "success" };
    },
  },
  mounted() {
    this.fetchInfo();
  },
  methods: {
    async fetchInfo() {
      if (this.instance.hostingStatus != "active") {
        this.fetching = false;
        return;
      }
      this.fetching = true;

      await Api.dns()
        .resolveARecord(this.instance.domain)
        .then((result) => {
          if (result) {
            this.dns.resolved = true;
            this.dns.ip_correct = result == this.instance.host_ip_address;
          }
        });

      if (this.instance.service_has_dns_cloudflare && (!this.dns.resolved || !this.dns.ip_correct)) {
        await Api.dns()
          .nsResolve(this.instance.domain)
          .then(result => {
            this.dns.resolved = true;
            for (const nameserver of result) {
              this.dns.ip_correct = !!nameserver.includes('cloudflare');
            }
          })
      }

      if (!this.dns.resolved || !this.dns.ip_correct) {
        this.fetching = false;
        return;
      }

      await Api.hosting(this.instance.hostingId)
        .getCertificate(this.instance.domain)
        .then((response) => {
          this.ssl.certificate_installed = response ? true : false;
          this.ssl.certificate = response;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })

      if (
        !this.ssl.certificate
        || (this.ssl.certificate && this.ssl.certificate.self_signed)
      ) {
        await Api.hosting(this.instance.hostingId)
          .getDetectedCertificate(this.instance.domain)
          .then(response => {
              this.ssl.certificate_installed = !!response;
              this.ssl.certificate = response;
          })
          .catch((error) => {
            this.$store.dispatch("addAlert", {
              success: false,
              errorMessage: Api.getErrorMessage(error),
            });
          })
      }
      this.fetching = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
