<template>
  <div
    @drop.prevent="uploadFile($event)"
    @drag.prevent="drag"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
  >
    <v-form>
      <LogoBox 
        :fileData="lightLogo"
        @update:file="$emit('update:lightLogoData')"
        @update:fileData="emitLight" 
        :text="$t('form.label.image.lightMode')"
        type="light"
      />
      <LogoBox 
        :fileData="darkLogo"
        @update:file="$emit('update:darkLogoData')"
        @update:fileData="emitDark" 
        :text="$t('form.label.image.darkMode')"
        type="dark"
      />
    </v-form>
    <p class="p-5 mb-0 font-weight-regular mt-1 gray-darken-text">
      {{ $t('form.label.image.subtitle') }}
    </p>
  </div>
</template>

<script>
import LogoBox from './StyleManagerLogoBox.vue'
export default {
  components: {
    LogoBox
  },
  props: {
    lightLogo: [String, Boolean],
    darkLogo: [String, Boolean],

  },
  methods: {
    emitLight(data) {
      this.$emit('update:lightLogoData', data)
    },
    emitDark(data) {
      this.$emit('update:darkLogoData', data)
    }
  }
};
</script>

<style lang="scss" scoped>


.v-form {
  display: flex;
  gap: 16px;
  flex-wrap: nowrap;
}

.v-application--is-rtl{
    .upload-img{
        &__icon{
            margin-right: 0px;
            margin-left: 14px;
        }

    }
}
</style>