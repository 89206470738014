<template>
  <div class="d-flex justify-center loader">
    <v-progress-circular
      fill-height
      :indeterminate="completed ? false : true"
      :size="size"
      :width="width ? width : computedWidth"
      :value="completed ? '100' : '30'"
      :color="color"
      :class="[!noMargins ? 'my-4' : '']"
    >
      <slot name="default"> </slot>
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  props: {
    completed: {
      type: Boolean,
      default: false,
    },
    noMargins: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [String, Number],
      default: "60",
    },
    color: {
      type: String,
      default: "primary",
    },
    width: {
      type: [Number, String],
    },
  },
  computed: {
    computedWidth: function () {
      return this.size / 6;
    },
  },
};
</script>