<template>
  <svg width="74" height="71" viewBox="0 0 74 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.31077 47.1185C-7.38322 36.9575 9.25187 4.72746 32.8335 0.658479C59.3171 -3.91625 77.8945 16.0379 73.3015 39.0495C68.2287 64.4289 34.9357 77.1186 25.4299 68.1071C19.8545 62.8197 29.1546 56.3139 22.2994 49.8081C15.4443 43.3024 8.315 51.8771 3.33362 47.1185H3.31077Z" fill="#D14F4F" fill-opacity="0.15"/>
    <path d="M64.5781 11H21.4219C20.0843 11 19 12.1328 19 13.5301V50.4699C19 51.8672 20.0843 53 21.4219 53H64.5781C65.9157 53 67 51.8672 67 50.4699V13.5301C67 12.1328 65.9157 11 64.5781 11Z" fill="var(--v-illustrations-lighten1)" stroke="var(--v-illustrations-darken2)" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M58.5781 17H15.4219C14.0843 17 13 18.1328 13 19.5301V56.4699C13 57.8672 14.0843 59 15.4219 59H58.5781C59.9157 59 61 57.8672 61 56.4699V19.5301C61 18.1328 59.9157 17 58.5781 17Z" fill="var(--v-illustrations-lighten3)" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <mask id="mask0_774_56574" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="20" y="46" width="32" height="4">
    <path d="M50.3854 46H21.6146C20.7229 46 20 46.8954 20 48C20 49.1046 20.7229 50 21.6146 50H50.3854C51.2771 50 52 49.1046 52 48C52 46.8954 51.2771 46 50.3854 46Z" fill="#EEEEF4"/>
    </mask>
    <g mask="url(#mask0_774_56574)">
    <path d="M18.8474 41.4518L17.2617 43.4297L26.966 55.5346L28.5516 53.5566L18.8474 41.4518Z" fill="var(--v-illustrations-lighten1)"/>
    <path d="M25.195 41.4479L23.6094 43.4258L33.3136 55.5307L34.8993 53.5527L25.195 41.4479Z" fill="var(--v-illustrations-lighten1)"/>
    <path d="M31.5466 41.4479L29.9609 43.4258L39.6652 55.5307L41.2509 53.5527L31.5466 41.4479Z" fill="var(--v-illustrations-lighten1)"/>
    <path d="M37.8786 41.4635L36.293 43.4414L45.9972 55.5463L47.5829 53.5684L37.8786 41.4635Z" fill="var(--v-illustrations-lighten1)"/>
    <path d="M44.2042 41.458L42.6185 43.4359L10.5347 34.4514L-0.677665 49.836L52.3228 55.5408L66.5963 43.4359L44.2042 41.458Z" fill="var(--v-illustrations-lighten1)"/>
    <path d="M50.5544 41.4557L48.9688 43.4336L58.673 55.5385L60.2587 53.5605L50.5544 41.4557Z" fill="var(--v-illustrations-lighten1)"/>
    </g>
    <path d="M29.3047 33.0009C29.3047 36.0594 31.0846 38.6917 33.6668 39.9452L29.9816 29.8672C29.5554 30.8198 29.3047 31.8978 29.3047 33.0009Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M42.2143 32.5993C42.2143 31.6467 41.8634 30.9949 41.5876 30.4684C41.1865 29.8416 40.8355 29.2901 40.8355 28.6634C40.8355 27.9614 41.362 27.2845 42.1391 27.2845C42.1642 27.2845 42.2143 27.2845 42.2394 27.2845C40.8606 26.0311 39.0305 25.2539 37.0249 25.2539C34.3174 25.2539 31.9609 26.6327 30.582 28.7386C30.7575 28.7386 30.933 28.7386 31.0834 28.7386C31.8857 28.7386 33.1391 28.6383 33.1391 28.6383C33.5653 28.6383 33.6155 29.2149 33.1893 29.265C33.1893 29.265 32.7631 29.3152 32.3118 29.3403L35.1196 37.7135L36.7993 32.6494L35.596 29.3653C35.1698 29.3653 34.7937 29.2901 34.7937 29.2901C34.3675 29.2901 34.4177 28.6383 34.8439 28.6634C34.8439 28.6634 36.1224 28.7637 36.8745 28.7637C37.6767 28.7637 38.9302 28.6634 38.9302 28.6634C39.3564 28.6634 39.4065 29.24 38.9804 29.2901C38.9804 29.2901 38.5542 29.3403 38.1029 29.3653L40.8857 37.6634L41.6628 35.0812C41.9887 34.0032 42.2394 33.2511 42.2394 32.5742L42.2143 32.5993Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M37.1502 33.6758L34.8438 40.3944C35.5457 40.595 36.2727 40.7203 37.0248 40.7203C37.9273 40.7203 38.7797 40.5699 39.5819 40.2942C39.5819 40.2691 39.5318 40.219 39.5318 40.1939L37.1502 33.7008V33.6758Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M43.7932 29.2891C43.8182 29.5398 43.8433 29.7905 43.8433 30.0913C43.8433 30.8684 43.6929 31.7459 43.2667 32.849L40.9102 39.6679C43.2166 38.3392 44.7458 35.8322 44.7458 32.9994C44.7458 31.6456 44.3948 30.3921 43.7932 29.2891Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M37 24C32.0362 24 28 28.0362 28 33C28 37.9638 32.0362 42 37 42C41.9638 42 46 37.9638 46 33C46 28.0362 41.9638 24 37 24ZM37 41.5989C32.2618 41.5989 28.4011 37.7382 28.4011 33C28.4011 28.2618 32.2618 24.4011 37 24.4011C41.7382 24.4011 45.5989 28.2618 45.5989 33C45.5989 37.7382 41.7382 41.5989 37 41.5989Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M65 62C69.9706 62 74 57.9706 74 53C74 48.0294 69.9706 44 65 44C60.0294 44 56 48.0294 56 53C56 57.9706 60.0294 62 65 62Z" fill="#D14F4F"/>
    <mask id="mask1_774_56574" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="20" y="46" width="32" height="4">
    <path d="M50.3854 46H21.6146C20.7229 46 20 46.8954 20 48C20 49.1046 20.7229 50 21.6146 50H50.3854C51.2771 50 52 49.1046 52 48C52 46.8954 51.2771 46 50.3854 46Z" fill="#EEEEF4"/>
    </mask>
    <g mask="url(#mask1_774_56574)">
    <path d="M18.8474 41.4518L17.2617 43.4297L26.966 55.5346L28.5516 53.5566L18.8474 41.4518Z" fill="var(--v-illustrations-darken1)"/>
    <path d="M25.195 41.4479L23.6094 43.4258L33.3136 55.5307L34.8993 53.5527L25.195 41.4479Z" fill="var(--v-illustrations-darken1)"/>
    <path d="M31.5466 41.444L29.9609 43.4219L39.6652 55.5268L41.2509 53.5488L31.5466 41.444Z" fill="var(--v-illustrations-darken1)"/>
    <path d="M37.8786 41.4635L36.293 43.4414L45.9972 55.5463L47.5829 53.5684L37.8786 41.4635Z" fill="var(--v-illustrations-darken1)"/>
    <path d="M44.2029 41.4596L42.6172 43.4375L52.3214 55.5424L53.9071 53.5645L44.2029 41.4596Z" fill="var(--v-illustrations-darken1)"/>
    <path d="M50.5544 41.4557L48.9688 43.4336L58.673 55.5385L60.2587 53.5605L50.5544 41.4557Z" fill="var(--v-illustrations-darken1)"/>
    </g>
    <path d="M68 50L62 56" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M62 50L68 56" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    
</template>

<script>
export default {};
</script>

<style>
</style>