<template>
  <div class="px-6">
    <instance-no-data
      v-if="
        items.length === 0 && !itemsLoading && !filtersActive && !loadingFailure
      "
    />

    <template v-else>
      <v-container class="px-0">
        <div
          class="d-flex justify-space-between flex-lg-row flex-column"
          :class="$vuetify.breakpoint.lgAndDown ? 'mt-3 mb-3' : 'mt-7 mb-7'"
        >
          <div
            class="d-flex align-center justify-lg-start justify-space-between"
          >
            <h1 class="font-weight-800">{{ $t('heading.instance.title') }}</h1>

            <list-grid-switch :listStyle.sync="instancesListStyle"></list-grid-switch>
          </div>

          <div
            class="d-flex"
            :class="$vuetify.breakpoint.lgAndDown ? 'mt-4' : ''"
          >
            <data-iterator-filter-controls
              :filter="true"
              :sortBy="!instancesListStyle ? sortBy : false"
              :sortDesc="sortDesc"
              :keys="keys"
              :showSearchTerm="true"
              :searchTerm="searchTerm"
              :fullWidthSearch="true"
              :filterLabels="filterLabels"
              @update:sortBy="changeSortBy"
              @update:sortDesc="handleSortDescChange"
              @update:searchTerm="handleSearchTermChange"
              @update:filterLabels="handleFilterLabels"
            ></data-iterator-filter-controls>
          </div>
        </div>
      </v-container>

      <v-divider
        :class="$vuetify.breakpoint.lgAndDown ? 'mb-3' : 'mb-10'"
      ></v-divider>

      <div :class="checkedItems.length > 0 ? 'pb-15' : ''">
        <template v-if="instancesListStyle === 0">
          <data-iterator
            :searchTerm="searchTerm"
            :listStyle="instancesListStyle"
            :items="items"
            :itemsTotal="itemsTotal"
            :possibleItemsPerPage="[[8, 16, 32, $t('general.all')]]"
            :itemsPerPage="itemsPerPage"
            :page="page"
            :keys="keys"
            :sortBy="sortBy"
            :sortDesc="sortDesc"
            :loading="itemsLoading"
            :filterLabels="filterLabels"
            @update:page="changePage"
            @update:itemsPerPage="changePerPage"
            @clearAllFilters="clearFilters"
          >
            <template v-slot:loading>
              <v-row class="py-3">
                <v-col
                  v-for="(item, key) in 8"
                  :key="key"
                  cols="12"
                  xs="12"
                  sm="6"
                  md="6"
                  xl="3"
                  class="responsive-card"
                >
                  <instance-item-skeleton />
                </v-col>
              </v-row>
            </template>

            <template v-slot:grid>
              <v-col
                v-for="(item, key) in items"
                :key="key"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                xl="3"
                class="responsive-card"
              >
                <instance-item
                  @checked="handleItemCheck(item)"
                  :checked="checkedItems.includes(item.id)"
                  :item="item"
                  :key="item.id"
                  @toggleFilter="toggleFilterLabel"
                  @action-button-wplogin="showSsoModal"
                  @action-button-staging="showStagingModal"
                  @action-button-update="showUpdateModal"
                  @action-button-clearcache="showClearCacheModal"
                  @action-button-delete="showDeleteModal"
                  @instance-deleted="$emit('instance-deleted')"
                  @unsuspend="showUnsuspendInstance"
                ></instance-item>
              </v-col>
            </template>
          </data-iterator>
        </template>

        <template v-if="instancesListStyle === 1">
          <instance-table
            :checkedItems="checkedItems"
            :items="tableItems"
            :itemsLoading="itemsLoading"
            :itemsPerPage="itemsPerPage"
            :itemsTotal="itemsTotal"
            :keys="keys"
            :listStyle="0"
            :page="page"
            :possibleItemsPerPage="[[8, 16, 32, $t('general.all')]]"
            :searchTerm="searchTerm"
            :filterLabels="filterLabels"
            :sortBy="sortBy"
            :sortDesc="sortDesc"
            @update:checked-items="handleItemCheck"
            @update:check-all="handleSelectAll"
            @update:page="changePage"
            @update:itemsPerPage="changePerPage"
            @update:sortDesc="changeSortDesc"
            @update:sortBy="changeSortBy"
            @toggleFilter="toggleFilterLabel"
            @action-button-wplogin="showSsoModal"
            @action-button-staging="showStagingModal"
            @action-button-update="showUpdateModal"
            @action-button-clearcache="showClearCacheModal"
            @clearAllFilters="clearFilters"
            @unsuspend="showUnsuspendInstance"
          />
        </template>

        <instance-action-snackbar
          @update:check-all="handleSelectAll"
          @mass-action-clear-cache="showMassClearCacheModal"
          @mass-action-share="showShareInstanceModal"
          @mass-action-remove="massActionRemove"
          :checkedItems="checkedItems"
          :items="items"
        />
      </div>
    </template>
    <v-dialog
      transition="custom-dialog-transition"
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        :style="
          modalOptions.type !== 'suspend'
            ? `width: 560px`
            : `width: 360px`
        "
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
      />
    </v-dialog>
    <share-instance-modal
      ref="shareInstanceModal"
      :multiInstances="selectedInstances"
    />
  </div>
</template>

<script>
import Api from "@/apis/Api";

import DataIteratorPageMixin from "../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../mixins/CustomTablePageMixin";
import InstanceActionsMixin from "@/mixins/InstanceActionsMixin";
import ActionModalMixin from "../mixins/ActionModalMixin";

import ListGridSwitch from "../components/buttons/ListGridSwitch.vue";
import DataIterator from "../components/dataIterator/DataIterator.vue";
import InstanceItem from "../components/instances/InstanceItem.vue";
import DataIteratorFilterControls from "../components/dataIterator/DataIteratorFilterControls.vue";
import InstanceActionSnackbar from "../components/instances/InstanceActionSnackbar.vue";
import InstanceTable from "../components/instances/InstanceTable.vue";
import InstanceItemSkeleton from "../components/instances/InstanceItemSkeleton.vue";
import InstanceNoData from "../components/instances/InstanceNoData.vue";
import BasicModal from "@/components/modal/BasicModal";
import ShareInstanceModal from "@/components/modal/ShareInstanceModal";

const _ = require("lodash");

export default {
  components: {
    ListGridSwitch,
    DataIterator,
    InstanceItem,
    DataIteratorFilterControls,
    InstanceActionSnackbar,
    InstanceTable,
    BasicModal,
    InstanceItemSkeleton,
    InstanceNoData,
    ShareInstanceModal,
  },

  mixins: [
    DataIteratorPageMixin,
    CustomTablePageMixin,
    InstanceActionsMixin,
    ActionModalMixin,
  ],
  watch: {
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  data: function () {
    return {
      loading: false,
      loadingFailure: false,
      itemsLoading: true,
      keys: [
        {
          text: "",
          value: "select",
          sortable: false,
        },
        {
          text: this.$t('table.header.name'),
          value: "name",
          groupable: true,
          sortable: true,
          align: "start",
          customClass: 'title'
        },
        {
          text: this.$t('table.header.status'),
          groupable: true,
          sortable: false,
          value: "subInstances[0].status.value",
        },
        {
          text: this.$t('table.header.visitors'),
          groupable: true,
          sortable: true,
          value: "visitors",
        },
        {
          text: this.$t('table.header.bandwidth'),
          groupable: true,
          sortable: true,
          value: "bandwidth",
        },
        {
          text: this.$t('table.header.storage'),
          groupable: true,
          sortable: true,
          value: "storage",
        },
        {
          text: this.$t('table.header.tags'),
          value: "tags",
          sortable: false,
        },
        {
          text: this.$t('table.header.shares'),
          value: "shares",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t('table.header.newest'),
          value: "created_at",
          sortable: true,
        },
        {
          text: this.$t('table.header.actions'),
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      sortBy: {
        text: this.$t('table.header.newest'),
        value: "created_at",
        sortable: true,
        align: "end",
      },
      sortDesc: true,
      items: [],
      checkedItems: [],
      displayTimeperiod: this.$t('general.all'),
      page: 1,
      itemsPerPage: 8,
      searchTerm: "",
      itemsTotal: 0,
      refreshSwitch: true,
    };
  },

  mounted() {
    this.$root.$on("manage-first-instance", () => {
      // for demo purposes
      if (this.items.length > 0) {
        this.$router.push(`/instances/${this.items[0].id}/general`);
      }
    });
    this.$on("instance-deleted", () => {
      this.page = 1;
      this.loadInstances();
    });
    this.$root.$on("instance-created", () => {
      this.sortDesc = true;
      this.changeSortBy({
        text: this.$t('table.header.newest'),
        value: "created_at",
      });
    });
    this.$root.$on("instance-item-changed", (instance) => {
      Api.hosting(instance.server_account_id).resetCache();
      for (const [key, item] of this.items.entries()) {
        if (item.id === instance.id) {
          this.items[key] = Api.instances().instanceResource(instance);
          this.items = [...this.items];
          return;
        }
      }
    });
    this.$root.$on("instance-updated", (instance) => {
      for (const [key, item] of this.items.entries()) {
        if (item.id === instance.id) {
          this.items[key] = instance;
          this.items = [...this.items];
          return;
        }
      }
    });

    Api.cached()
      .get("/labels")
      .then((response) => {
        this.setLabels(response.data.data);
      })
      .catch(() => {});

    this.loadInstances();
  },

  methods: {
    clearFilters: function () {
      this.searchTerm = "";
      this.filterLabels = [];
      this.loadInstances();
    },
    async loadInstances() {
      this.itemsLoading = true;

      let labels = this.filterLabels.map((label) => {
        return label.title;
      });

      let { data, meta, error } = await Api.instances().list(
        this.page,
        this.itemsPerPage,
        this.sortBy.value,
        this.sortDesc,
        this.searchTerm,
        labels
      );

      this.itemsLoading = false;

      if (error) {
        this.loadingFailure = true;
        return;
      }

      this.itemsTotal = meta.total;
      this.setInstances(data);
    },
    toggleFilterLabel: function (item) {
      let filtered = this.filterLabels.filter((label) => {
        return label.title == item.title;
      });

      if (filtered.length > 0) {
        let filtered = this.filterLabels.filter((label) => {
          return label.title !== item.title;
        });

        this.handleFilterLabels(filtered);
      } else {
        this.handleFilterLabels(this.filterLabels.concat(item));
      }
    },
    changePage(page) {
      this.page = page;
      this.loadInstances();
    },
    changePerPage(perPage) {
      this.page = 1;
      this.itemsPerPage = perPage;
      this.loadInstances();
    },
    changeSortBy: function (data) {
      this.page = 1;
      this.sortBy = data;
      this.loadInstances();
    },
    changeSortDesc: function (data) {
      this.page = 1;
      this.sortDesc = data;
      this.loadInstances();
    },
    handleSearchTermChange: _.debounce(function (newValue) {
      this.page = 1;
      this.searchTerm = newValue;
      this.loadInstances();
    }, 300),
    handleFilterLabels: function (newValue) {
      this.page = 1;
      this.filterLabels = newValue;
      this.loadInstances();
    },
    setInstances: function (data) {
      this.items = data;
    },
    sortChange: function (payload) {
      this.displayTimeperiod = payload;
    },
    setLabels(data) {
      this.$store.dispatch("setUserLabels", data);
    },
    showShareInstanceModal() {
      this.$refs.shareInstanceModal.isOpen = true;
    },
    massActionRemove() {
      const selected = this.items.filter((item) => {
        return this.checkedItems.includes(item.id);
      });
      this.showMassDeleteModal(selected);
    },
  },
  computed: {
    instancesListStyle: {
      get(){
        this.refreshSwitch;
        return parseInt(localStorage.getItem('instancesListStyle')) ? 1 : 0;
      },
      set(value){
        localStorage.setItem('instancesListStyle', value);
        this.refreshSwitch = !this.refreshSwitch;
      },
    },
    selectedInstances() {
      return this.items.filter((item) => {
        return this.checkedItems.includes(item.id);
      });
    },
    tableItems() {
      let tableItems = [];
      for (let item of this.items) {
        let tableItem = { ...item };
        tableItem.subInstances = [{ ...tableItem }];
        for (const subItem of item.subInstances) {
          tableItem.subInstances.push({ ...subItem });
        }
        tableItems.push(tableItem);
      }
      return tableItems;
    },
    filtersActive: function () {
      if (this.filterLabels.length > 0 || this.searchTerm) {
        return true;
      } else {
        return false;
      }
    },
  },
  created: function () {
    if (this.$route.params.filter) {
      this.filterLabels = [...this.filterLabels, this.$route.params.filter];
    }
  },
};
</script>

<style scoped>
.v-divider {
  margin-left: -24px;
  min-width: calc(100% + 48px);
}
</style>
