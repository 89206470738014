<template>
  <div class="notifications-manager">
    <div class="notifications-manager__content">
      <div
        class="notification"
        v-for="(item, index) of notifications"
        :key="item.id + index"
      >
        <div class="notification__content p-3">
          <template v-if="item.data.html_text">
            <div v-html="item.data.html_text" />
          </template>
          <template v-else>
            <span> {{ item.type }} </span>
          </template>
        </div>
        <div class="notification__info">
          <div class="notification__status" :class="item.status"></div>
          <div class="notification__time p-3">{{ item.created_at_text }}</div>
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                v-on="on"
                v-bind="attrs"
                @click="$emit('remove-notification', item)"
              >
                <v-icon size="16">$close</v-icon>
              </v-btn>
            </template>
            {{ $t('button.dismiss') }}
          </v-tooltip>
        </div>
      </div>

      <div
        class="infinite-loader"
        ref="infinite-loader"
        v-if="!allNotificationsLoaded && notifications.length >= 10"
      >
        <Loader size="24" />
      </div>
    </div>
    <div class="notifications-manager__clear">
      <v-btn text block color="info" @click="clearAll">
        {{ $t('button.notification.clear') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Loader from "../Loader.vue";

export default {
  components: {
    Loader,
  },
  props: {
    notifications: {
      type: Array,
      default: () => {
        [];
      },
    },
  },
  data: function () {
    return {
      observer: null,
    };
  },
  mounted: function () {
    this.createObserver();
  },
  computed: {
    allNotificationsLoaded: function () {
      return this.$store.state.NotificationsModule.allNotificationsLoaded;
    },
  },
  methods: {
    createObserver: function () {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };
      this.observer = new IntersectionObserver(this.handleObserver, options);

      if (this.$refs["infinite-loader"]) {
        this.observer.observe(this.$refs["infinite-loader"]);
      }
    },
    handleObserver: function (entries) {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.$emit("loadNextPage");
      }
    },
    clearAll: function () {
      this.$emit("clearAll");
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications-manager {
  overflow: overlay;

  &__content {
    padding: 5px 0;
    max-height: 274px;
    scrollbar-gutter: stable;
    overflow-y: overlay;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
      background-color: transparent;
      max-width: 8px;
      border: 3px solid transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--v-gray-lighten2);
      border-radius: 8px;
      border: 4px solid transparent;
      background-clip: padding-box;
    }
  }

  &__clear {
    padding: 5px 0;
    border-top: 1px solid map-get($gray, lighten2);
  }
}

.notification {
  padding: 8px 12px 8px 24px;
  position: relative;

  &__content {
    margin-bottom: 6px;

    > a {
      color: var(--v-text-darken1);
      font-weight: $font-weight-bold;
      transition: color 0.24s ease;

      &:hover {
        color: var(--v-primary-base);
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .v-btn {
      margin-left: auto;
    }
  }

  &__time {
    color: map-get($gray, darken1);
  }

  &__status {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 12px;
    background-color: map-get($primary, base);

    &.error {
      background-color: map-get($error, base);
    }
  }

  &:not(:last-child) {
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0px;
      width: calc(100% + 8px);
      height: 1px;
      background-color: map-get($gray, lighten2);
      z-index: 999;
    }
  }
}

.infinite-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}
    .v-application--is-rtl{
            .notification{
                padding: 8px 24px 8px 12px;
            &__status {
                margin-right: 0px;
                margin-left: 12px;
            }
            &__info {
            .v-btn {
            margin-left: unset;
            margin-right: auto;
            }
        }
    }
}
</style>
