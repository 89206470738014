<template>
  <v-chip
    class="white--text p-4 label-chip mb-0"
    :color="item.color || item.colorScheme"
    :class="[
      small ? 'label-chip--small' : '',
      xsmall ? 'label-chip--xsmall' : '',
      removable ? 'pr-0' : '',
    ]"
    v-on="clickable ? { click: filterClick } : {}"
  >
    {{ item.title }}
    <v-btn
      @click.stop="$emit('unassign-label', item)"
      icon
      v-if="removable"
      x-small
      class="ml-1"
    >
      <v-icon size="12" class="white--text"> $close </v-icon>
    </v-btn>
  </v-chip>
</template>

<script>
export default {
  props: {
    item: Object,
    small: Boolean,
    xsmall: Boolean,
    removable: Boolean,
    clickable: Boolean,
  },
  methods: {
    filterClick: function () {
      this.$emit("toggleFilter", {
        title: this.item.title,
        color: this.item.color,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.labels-list {
  .label-chip {
    margin-bottom: 8px !important;
  }
}

.label-chip {
  margin-bottom: 8px;
  height: 28px;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-weight: $font-weight-bold;
  transition: opacity ease 0.3s;
  &:hover {
    opacity: 0.8;
  }
  .v-btn--icon.v-size--x-small {
    width: 24px;
    height: 24px;
    margin-right: 2px;

    padding: 0px !important;
    position: relative;
    &:hover {
      &::before {
        opacity: 0.32;
      }
    }
  }
  &--small {
    height: 24px;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    font-weight: $font-weight-semibold;
    .v-btn--icon.v-size--x-small {
      width: 20px;
      height: 20px;
      padding: 0px !important;
      margin-right: 2px;
      position: relative;
      &:hover {
        &::before {
          opacity: 0.32;
        }
      }
    }
  }
  &--xsmall {
    height: 20px;
    font-size: $font-size-xxs;
    line-height: $line-height-xxs;
    font-weight: $font-weight-semibold;
    padding: 0px 8px;
    .v-btn--icon.v-size--x-small {
      width: 18px;
      height: 18px;
      padding: 0px !important;
      margin-right: 1px;
      position: relative;
      &:hover {
        &::before {
          opacity: 0.32;
        }
      }
    }
  }
  + .label-chip,
  &:first-child {
    margin-right: 8px;
  }
}
.v-application--is-rtl{
    .label-chip{
        padding-right: 8px !important;
        + .label-chip,
        &:first-child {
        margin-right: 0px !important;
        margin-left: 8px !important;
      }
    }
}
</style>