<template>
  <div
    class="label-simple"
    :class="[
      small ? 'label-simple--small' : '',
      dot ? 'label-simple--dot' : '',
      item.classColor,
      hasCustomDot ? 'label-simple--custom-dot' : '',
    ]"
    :style="{ color: item.color }"
  >
    <div v-if="hasCustomDot" class="custom-dot">
      <slot />
    </div>

    {{ dot ? "" : item.title }}
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    dot: Boolean,
    small: Boolean,
  },
  computed: {
    hasCustomDot: function () {
      return this.$slots.default;
    },
  },
};
</script>

<style lang="scss" scoped>
.label-simple {
  position: relative;
  padding-left: 18px;
  margin-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-weight: $font-weight-normal;
  transition: opacity 0.24s ease;
  &:hover {
    opacity: 0.6;
  }
  &--dot {
    padding-left: 8px;
  }
  &--small {
    padding-left: 14px;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    font-weight: $font-weight-normal;
  }
  &--custom-dot {
    padding-left: 24px;
    display: flex;
    align-items: center;
    .custom-dot {
      position: absolute;
      left: 0;
      top: calc(50% - 9px);
      width: 16px;
      height: 16px;
      margin-right: 6px;
      display: flex;
      align-items: center;
    }
    &::before {
      display: none;
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: calc(50% - 5px);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 6px;
    background-color: currentColor;
  }
}
</style>