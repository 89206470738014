<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7273 8.63625C18.4849 10.3029 19.3636 14.1817 16.8182 16.3635H3.18182C-1.36361 12.7272 2.63637 1.18171 13.1818 4.0908" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    <path d="M16.8182 5L10 11.8182" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    <circle cx="9.99994" cy="11.8182" r="1.81818" fill="currentColor"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>