<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2 19.5H2.8C1.5 19.5 0.5 18.5 0.5 17.2V4.10005C0.5 2.80005 1.5 1.80005 2.8 1.80005H21.3C22.6 1.80005 23.6 2.80005 23.6 4.10005V17.2C23.6 18.5 22.5 19.5 21.2 19.5ZM2.8 3.80005C2.6 3.80005 2.5 3.90005 2.5 4.10005V17.2C2.5 17.4 2.6 17.5 2.8 17.5H21.3C21.5 17.5 21.6 17.4 21.6 17.2V4.10005C21.6 3.90005 21.5 3.80005 21.3 3.80005H2.8Z"
      fill="#CBCBDC"
    />
    <path
      d="M15.5 22.2H8.49999C8.19999 22.2 7.89999 22 7.69999 21.8C7.49999 21.5 7.49999 21.2 7.59999 20.9L8.49999 18.2C8.59999 17.8 8.99999 17.5 9.49999 17.5H14.8C15.2 17.5 15.6 17.8 15.8 18.2L16.7 20.9C16.8 21.2 16.7 21.5 16.6 21.8C16.1 22.1 15.8 22.2 15.5 22.2ZM9.89999 20.2H14.2L14 19.5H10.2L9.89999 20.2Z"
      fill="#CBCBDC"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>