<template>
  <div>
    <v-container class="mt-13">
      <v-row align="stretch">
        <v-col cols="12" class="d-flex flex-column">
          <h1>{{ $t('heading.errorPage.title') }}</h1>
          <span>{{ $t('heading.errorPage.subtitle') }}</span>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {

};
</script>
