const DataIteratorPageMixin = {
    data: function () {
        return {
            listStyle: 0,
            searchTerm: "",
            keys: false,
            sortBy: false,
            sortDesc: true,
            filterLabels: [],
            isServerProvided: true,
        };
    },
    methods: {
        handleSortDescChange: function (newValue) {
            this.sortDesc = newValue
        },
        changePage(page) {
            this.page = page;
            if (this.isServerProvided) {
                this.reloadData();
            }
        },
        changePerPage(perPage) {
            this.page = 1;
            this.itemsPerPage = perPage;
            if (this.isServerProvided) {
                this.reloadData();
            }
        },
        changeSortBy: function (data) {
            this.page = 1;
            this.sortBy = data;
            if (this.isServerProvided) {
                this.reloadData();
            }
        },
        changeSortDesc: function (data) {
            this.page = 1;
            this.sortDesc = data;
            if (this.isServerProvided) {
                this.reloadData();
            }
        },
        handleSearchTermChange: function (newValue) {
            this.page = 1;
            this.searchTerm = newValue;
            if (this.isServerProvided) {
                this.reloadData();
            }
        },
    },
    computed: {
        activeItems: function () {
            return this.items.slice((this.page - 1) * this.itemsPerPage, (this.page) * this.itemsPerPage)
        }
    }
}

export default DataIteratorPageMixin