<template>
  <v-app :class="[style.disableShadows ? 'disable-shadow' : '']" :key="key">
    <template v-if="globalError">
      <error-page />
    </template>
    <template v-else>
      <style-manager-menu v-if="isAdminLoggedIn" />

      <navbar v-if="$route.meta.displayNavbar && readyToRender" />
      <new-instance-floating-button
        v-if="$route.meta.displayNavbar && $vuetify.breakpoint.mobile"
      />
      <v-main
        v-if="readyToRender"
        app
        :class="$route.meta.displayNavbar ? '' : ''"
      >
        <alert-manager />
        <router-view />

        <demo-send-feedback-button v-if="$store.state.demoMode" />

        <demo-footer
          v-if="$store.state.demoMode && $route.meta.displayNavbar"
        />

        <div
          v-else-if="$route.meta.displayNavbar && !$store.state.demoMode"
          class="mt-10 w-100"
        ></div>
      </v-main>
      <onboarding-modal-wrapper />
      <Footer v-if="$store.state.home && $store.state.home.user" @languageChanged="refreshApp"/>
    </template>
  </v-app>
</template>


<script>
import AlertManager from "./components/alerts/AlertManager.vue";
import NewInstanceFloatingButton from "./components/buttons/NewInstanceFloatingButton.vue";
import Navbar from "./components/navbar/Navbar.vue";
import Footer from './components/footer/Footer.vue'
import DemoFooter from "./components/footer/DemoFooter.vue";
import StyleManagerMenu from "./components/styleManager/StyleManagerMenu.vue";
import OnboardingModalWrapper from "./components/onboarding/OnboardingModalWrapper.vue";

import CustomThemeMixin from "./mixins/CustomThemeMixin.js";

import Api from "@/apis/Api";

import ErrorPage from "./views/ErrorPage.vue";
import DemoSendFeedbackButton from "./components/DemoSendFeedbackButton.vue";

export default {
  components: {
    Navbar,
    AlertManager,
    NewInstanceFloatingButton,
    ErrorPage,
    DemoFooter,
    DemoSendFeedbackButton,
    StyleManagerMenu,
    OnboardingModalWrapper,
    Footer
  },
  mixins: [CustomThemeMixin],
  name: "App",
  data() {
    return {
      readyToRender: true,
      globalError: false,
      key: 0
    };
  },
  mounted() {
    this.$vuetify.theme.dark = this.$store.state.home.app.theme?.style?.darkMode
  },
  beforeCreate() {
    document.body.classList.add(this.$store.state.ThemeModule.style?.darkMode ? 'is-dark' : 'is-light')
  },
  computed: {
    isAdminLoggedIn() {
      return "admin" in this.$store.state.home;
    },
  },
  created() {
    this.$root.$on("app-ready-to-render", (ready) => {
      this.readyToRender = ready;
    });
    Api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // Show alert when forbidden error code is received
        if (error.response.status === 403) {
          // this.$store.dispatch("addAlert", {
          //   success: false,
          //   errorMessage: "Action not available in demo mode.",
          // });
          if (process.env.VUE_APP_DEMO_MODE) {
            error.response.data.message = "Action not available in demo mode.";
          }
        }
        // if (error.response.status === 429) {
        //   this.globalError = true;
        // }
        return Promise.reject(error);
      }
    );
  },
  methods: {
    refreshApp() {
      this.key++;
    }
  }
};
</script>

