<template lang="">
  <v-chip
    small
    :style="`background-color: ${roleBg}; border-color: ${roleBg}`"
  >
    <span
      class="p-3"
      :style="`color: ${roleText}`"
    >
      {{ role }}
    </span>
  </v-chip>
</template>
<script>
export default {
  props: {
    role: String,
  },
  data() {
    return {
      roles: {
        administrator: {
          bg: 'var(--v-sand-lighten5)',
          text: 'var(--v-sand-darken2)',
          darkBg: 'var(--v-role1-bg)',
          darkText: 'var(--v-role1-text)'
        },
        author: {
          bg: '#e6f9f2',
          text: 'var(--v-primary-base)',
          darkBg: 'var(--v-role2-bg)',
          darkText: 'var(--v-role2-text)'
        },
        contributor: {
          bg: '#ffeff9',
          text: 'var(--v-role3-text)',
          darkBg: 'var(--v-role3-bg)',
          darkText: 'var(--v-role3-text)'
        },
        editor: {
          bg: '#ffebe0',
          text: 'var(--v-warning-base)',
          darkBg: 'var(--v-role4-bg)',
          darkText: 'var(--v-role4-text)'
        },
        subscriber: {
          bg: '#ebf2fd',
          text: 'var(--v-info-base)',
          darkBg: 'var(--v-role5-bg)',
          darkText: 'var(--v-role5-text)'
        },
        default: {
          bg: 'var(--v-gray-lighten5)',
          text: 'var(--v-gray-darken1)',
          darkBg: 'var(--v-gray-lighten5)',
          darkText: 'var(--v-gray-darken1)'
        },
      }
    }
  },
  computed: {
    roleBg() {
      return this.$vuetify.theme.dark ? this.roles[this.role].darkBg : this.roles[this.role].bg
    },
    roleText() {
      return this.$vuetify.theme.dark ? this.roles[this.role].darkText : this.roles[this.role].text
    }
  }
};
</script>

<style lang="scss" scoped>
.v-chip {
  text-transform: capitalize;
  font-weight: $font-weight-bold;
  &::before {
    display: none;
  }
}
</style>
