const AlertsModule = {
    state: {
        activeAlerts: [],
    },
    mutations: {
        applyAlerts: function (state, payload) {
            state.activeAlerts = payload
        },
    },
    actions: {
        addAlert: function (context, payload) {
            const newCopyAlert = {
                ...payload,
                id: Math.ceil(Math.random() * 1000),
            }

            let activeAlerts = context.state.activeAlerts
            const newAlerts = activeAlerts.concat(newCopyAlert)
            context.commit('applyAlerts', newAlerts)
        },
        removeAlert: function (context, payload) {
            const newState = context.state.activeAlerts.filter(item => {
                return item.id !== payload
            })
            context.commit('applyAlerts', newState)
        },
    }
}

export default AlertsModule