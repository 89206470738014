<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6847_205539)">
      <path
        d="M12 17.9973C15.3137 17.9973 18 15.311 18 11.9973C18 8.68361 15.3137 5.99731 12 5.99731C8.68629 5.99731 6 8.68361 6 11.9973C6 15.311 8.68629 17.9973 12 17.9973Z"
        fill="white"
      />
      <path
        d="M12 6.00002H22.3906C21.3377 4.17571 19.823 2.66077 17.9989 1.60752C16.1748 0.554277 14.1055 -0.000146935 11.9992 2.92099e-08C9.89285 0.000146994 7.82366 0.55486 5.9997 1.60836C4.17574 2.66186 2.66129 4.17702 1.60864 6.00147L6.80394 15L6.80859 14.9988C6.28009 14.0875 6.00119 13.0529 6.00001 11.9994C5.99883 10.9458 6.2754 9.91062 6.80185 8.99807C7.3283 8.08552 8.08602 7.32789 8.99863 6.80153C9.91123 6.27518 10.9465 5.99872 12 6.00002Z"
        fill="url(#paint0_linear_6847_205539)"
      />
      <path
        d="M12 16.75C14.6234 16.75 16.75 14.6234 16.75 12C16.75 9.37665 14.6234 7.25 12 7.25C9.37665 7.25 7.25 9.37665 7.25 12C7.25 14.6234 9.37665 16.75 12 16.75Z"
        fill="#1A73E8"
      />
      <path
        d="M17.1956 15.0015L12.0003 24C14.1067 24.0003 16.176 23.446 18.0001 22.3929C19.8243 21.3398 21.3391 19.825 22.3921 18.0008C23.4451 16.1766 23.9993 14.1072 23.999 12.0009C23.9986 9.89455 23.4437 7.82543 22.39 6.00159H11.9994L11.9982 6.00624C13.0517 6.0042 14.0871 6.27995 15.0001 6.80568C15.9131 7.3314 16.6713 8.08853 17.1984 9.00073C17.7254 9.91293 18.0027 10.948 18.0022 12.0015C18.0017 13.055 17.7235 14.0898 17.1956 15.0015Z"
        fill="url(#paint1_linear_6847_205539)"
      />
      <path
        d="M6.80431 15.0016L1.60901 6.00305C0.555576 7.82705 0.000932998 9.89626 0.000854501 12.0026C0.000776003 14.109 0.555265 16.1782 1.60857 18.0023C2.66187 19.8264 4.17686 21.341 6.00119 22.3938C7.82553 23.4467 9.89492 24.0007 12.0013 24.0001L17.1966 15.0015L17.1932 14.9981C16.6682 15.9115 15.9117 16.6703 14.9999 17.1981C14.0881 17.7259 13.0533 18.004 11.9998 18.0043C10.9463 18.0047 9.91126 17.7273 8.99912 17.2001C8.08699 16.6729 7.32995 15.9146 6.80431 15.0016Z"
        fill="url(#paint2_linear_6847_205539)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_6847_205539"
        x1="1.60864"
        y1="7.50002"
        x2="22.3906"
        y2="7.50002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D93025" />
        <stop offset="1" stop-color="#EA4335" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6847_205539"
        x1="10.3609"
        y1="23.8396"
        x2="20.7519"
        y2="5.84189"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FCC934" />
        <stop offset="1" stop-color="#FBBC04" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6847_205539"
        x1="13.2991"
        y1="23.2508"
        x2="2.90806"
        y2="5.25305"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1E8E3E" />
        <stop offset="1" stop-color="#34A853" />
      </linearGradient>
      <clipPath id="clip0_6847_205539">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>