<template>
    <div
        class="upload-img"
        :class="[
          fileIsLoaded || fileData ? 'upload-img--done' : '',
          hovering ? 'upload-img--hover' : '',
        ]"
    >
        <div class="upload-img__content">
            <input
              class="file-input"
              @change="uploadFile"
              type="file"
              ref="fileInput"
              accepts="image/*"
            />
            <div class="upload-img__icon" v-if="!fileIsLoaded && !fileData">
                <v-icon size="24">$backup</v-icon>
            </div>
            <div class="upload-img__text" v-if="fileData" :style="
              type === 'light' && $vuetify.theme.dark
              ? 'background-color: #fff'
              : type === 'dark' && !$vuetify.theme.dark
              ? 'background-color: var(--v-secondary-darken2)'
              : ''
            ">
                <img class="upload-img__preview-img" :src="fileData" alt="" v-if="!isSvg" />
                <div class="upload-img__preview-svg" v-html="fileData" v-else>
                    
                </div>
            </div>
            <div class="upload-img__control-btns" v-if="fileIsLoaded || fileData">
                <v-btn x-small text @click="replaceFile">
                    <v-icon class="mr-0" size="16" stroke="2">$restore</v-icon>
                </v-btn>
                <v-btn x-small text @click="clearFile">
                    <v-icon class="mr-0" size="16">$thrash</v-icon>
                </v-btn>
            </div>
        </div>

                <p class="mt-3 mb-0 text--darken2 font-weight-bold">{{text}}</p>

        <!-- <p class="mt-3 mb-0 text--gray-darken2 font-weight-bold">{{ $t('form.label.image.lightMode') }}</p> -->
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            hovering: false,
            file: false,
            isSvg: false,
        };
    },
    props: {
        fileData: [String, Boolean],
        text: String,
        type: String
    },
    watch: {
        fileUrl: function () {
            this.toDataURL(
            this.fileUrl,
            (data) => {
                this.$emit("update:fileData", data);
            },
            "image/png",
            this.file
            );
        },
        fileData: function (newValue) {
        if (!newValue) {
            this.file = false;
        }
        },
    },
    computed: {
        // displayMenuAsFileSelected() {

        // },
        fileIsLoaded: function () {
        return this.file.name && this.file.size ? true : false;
        },
        fileUrl: function () {
        if (!this.fileIsLoaded) {
            return false;
        }
        return URL.createObjectURL(this.file);
        },
    },
    methods: {
        uploadFile(event) {
            const data = event.target.files[0] || event.dataTransfer.files[0];
            this.file = data;
        },
        onDrop: function (event) {
            this.hovering = false;
            this.uploadFile(event);
        },
        drag: function () {},
        dragOver: function () {
            this.hovering = true;
        },
        dragEnter: function () {
            this.hovering = true;
        },
        dragLeave: function () {
            this.hovering = false;
        },
        clearFile: function () {
            this.file = false;
            this.$refs.fileInput.value = ''
            this.$emit("update:fileData", null);
        },
        replaceFile: function () {
            this.$refs.fileInput.click();
        },

        toDataURL: function (src, callback, outputFormat, file = null) {
        if (file && file.type === 'image/svg+xml') {
            this.isSvg = true
            var reader = new FileReader();
            reader.onload = function(event) {
                var svgContent = event.target.result;
                callback(svgContent);
            };
            reader.readAsText(file);
        } else {
            this.isSvg = false
            var img = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = function () {
            var canvas = document.createElement("CANVAS");
            var ctx = canvas.getContext("2d");
            var dataURL;
            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
                callback(dataURL);
            };
            img.src = src;
            if (img.complete || img.complete === undefined) {
            img.src =
                "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
            img.src = src;
            }
        }
        },
    },
}
</script>

<style lang="scss" scoped>
.upload-img {
  position: relative;
  width: 100%;
  // height: 75px;
  // cursor: pointer;
    flex-grow: 1;
    min-width: 100px;
  &.upload-img--hover,
  
    .upload-img__content {
      cursor: pointer;
      transition: border-color 0.24s ease, color 0.24s ease;
      &:hover {
        border-color: map-get($success, base);
        color: map-get($success, base);
        .upload-img__icon {
          .v-icon {
            color: map-get($success, base) !important;
          }
        }
      }
    }
  &.upload-img--done {
    .upload-img {
        &__content {
            border: 1px solid map-get($gray, lighten3);
            max-height: unset;
            height: fit-content;
        }
    }
    .v-btn {
      padding: 0 !important;
      width: 50%;
      border-top: 1px solid map-get($gray, lighten3);
      border-radius: 0;
      min-width: unset;
      &::before {
        background: #fff !important;
      }
      &:first-child {
        // border-right: 1px solid map-get($gray, lighten3);
        &:hover {
          color: map-get($error, base);
        }
      }
      &:nth-child(2) {
        border-left: 1px solid map-get($gray, lighten3);
        &:hover {
          color: map-get($success, base);
        }
      }
      &:hover {
        .v-icon::v-deep svg {
          color: map-get($success, base) !important;
        }
      }
    }
    .upload-img {
      &__content {
        // padding: 0;
        // max-height: 75px;
      }
      &__text {
        width: calc(100% - 4px);
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 4px);
        min-height: 75px;
      }
      &__control-btns {
        display: flex;
        width: 100%;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 100%;
    // padding: 20px;
    min-height: 75px;
    border: 1px dashed map-get($gray, lighten3);
    border-radius: 8px;
    flex-direction: column;
    position: relative;
  }
  // &__icon {
  //   margin-right: 14px;
  // }
  .file-input {
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    cursor: pointer;
    margin: 0 !important;
    padding: 0;
  }
  &__preview-img, &__preview-svg::v-deep svg {
    max-height: 75px;
    max-width: 100%;
    padding: 8px;
  }
  &__preview-svg::v-deep svg {
    width: 100%;
  }
}
</style>