const CustomTableMixin = {
    data: function () {
        return {
            headers: {},
            sortBy: {

            },
            checkedItems: [],
            items: [],
        }
    },
    methods: {
        handleItemCheck: function (item) {
            let id = item.id || item.name;
            if (!this.checkedItems.includes(id)) {
                this.checkedItems.push(id);
            } else {
                const index = this.checkedItems.indexOf(id);
                this.checkedItems.splice(index, 1);
            }
        },
        handleSelectAll(data) {
            this.items.forEach((item) => {
                let id = (item.id ? item.id : item.name);
                if (data) {
                    if (
                        !this.checkedItems.includes(id)
                    ) {
                        this.handleItemCheck(item);
                    }
                } else {
                    if (
                        this.checkedItems.includes(id)
                    ) {
                        this.handleItemCheck(item);
                    }
                }
            });
        },
    }

}

export default CustomTableMixin