<template>
  <div class="d-flex flex-column justify-center">
    <style-manager-editor
      v-for="(item, index) in selectedColors"
      :key="'color-' + index"
      @update:currentColor="applyChanges"
      :currentColor="item"
      :colorKey="index"
      :colorName="colorNames[index]"
    />
  </div>
</template>

<script>
import StyleManagerEditor from "./StyleManagerEditor.vue";

export default {
  components: {
    StyleManagerEditor,
  },
  data: function () {
    return {
      colorNames: {
        base: this.$t('form.option.color.base'),
        darken1: this.$t('form.option.color.darken1'),
        darken2: this.$t('form.option.color.darken2'),
        darken3: this.$t('form.option.color.darken3'),
        darken4: this.$t('form.option.color.darken4'),
        darken5: this.$t('form.option.color.darken5'),
        lighten1: this.$t('form.option.color.lighten1'),
        lighten2: this.$t('form.option.color.lighten2'),
        lighten3: this.$t('form.option.color.lighten3'),
        lighten4: this.$t('form.option.color.lighten4'),
        lighten5: this.$t('form.option.color.lighten5'),
      },
    };
  },
  props: {
    selectedColors: {
      type: Object,
    },
  },
  methods: {
    applyChanges: function (data) {
      let newTheme = { ...this.selectedColors, [data.index]: data.item };

      this.$emit("update:selectedColors", newTheme);
    },
  },
};
</script>

<style>
</style>