<template>
  <div>
    <svg
      v-if="$parent.size === '16'"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.0001 14.4C11.5347 14.4 14.4001 11.5346 14.4001 7.99998C14.4001 4.46535 11.5347 1.59998 8.0001 1.59998C4.46548 1.59998 1.6001 4.46535 1.6001 7.99998C1.6001 11.5346 4.46548 14.4 8.0001 14.4Z"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.3999 5.99999C6.52895 5.55477 6.78366 5.31466 7.11892 5.07552C7.45418 4.83638 7.84835 4.74897 8.23163 4.82876C8.6149 4.90855 8.96254 5.15039 9.21298 5.51146C9.46341 5.87253 9.60048 6.32951 9.5999 6.80148C9.5999 8.13382 7.95325 8.79999 7.95325 8.79999"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.79995 11.2C8.79995 11.6419 8.44178 12 7.99995 12C7.55812 12 7.19995 11.6419 7.19995 11.2C7.19995 10.7582 7.55812 10.4 7.99995 10.4C8.44178 10.4 8.79995 10.7582 8.79995 11.2Z"
        fill="currentColor"
      />
    </svg>

    <svg
      v-else
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 20C17.1944 20 21 16.1944 21 11.5C21 6.80558 17.1944 3 12.5 3C7.80558 3 4 6.80558 4 11.5C4 16.1944 7.80558 20 12.5 20Z"
        :stroke="iconColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 8.66915C10.2016 8.11262 10.5996 7.64334 11.1235 7.34442C11.6473 7.0455 12.2632 6.93623 12.8621 7.03596C13.4609 7.1357 14.0041 7.43801 14.3954 7.88934C14.7867 8.34067 15.0009 8.91191 15 9.50186C15 11.1673 12.4271 12 12.4271 12"
        :stroke="iconColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15Z"
        :fill="iconColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<style>
</style>