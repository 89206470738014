let Helper = {
	formatBytes: (bytes, decimals = 2) => {
		if (bytes === 0 || bytes === "0" || bytes === null) return '0 B';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	},

	capitalizeFirstLetter: (string) => {
		return string.replace(/\b\w/g, function (match) {
			return match.toUpperCase();
		});
	}
}

export default Helper;
