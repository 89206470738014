<template>
  <div
    class="checkbox"
    @mouseover="handleHover(true)"
    @mouseleave="handleHover(false)"
  >
    <v-checkbox
      :disabled="disabled"
      :ripple="false"
      :class="[
        checkedComputed ? 'active' : '',
        labellg ? 'labellg' : '',
        color ? `v-input--checkbox-${color}` : '',
      ]"
      size="16"
      :indeterminate="(hover || indeterminate)"
      v-model="checkedComputed"
      hide-details
      indeterminate-icon="$check"
      :label="label"
      :rules="rules"
      @click.prevent.stop
    />
  </div>
</template>

<script>
export default {
  props: {
    indeterminate: Boolean,
    checked: Boolean,
    disabled: Boolean,
    label: String,
    labellg: Boolean,
    rules: Array,
    color: String,
  },
  data: function () {
    return {
      hover: false,
    };
  },

  methods: {
    handleHover: function (value) {
      this.hover = value;
    },
  },
  computed: {
    checkedComputed: {
      get: function () {
        return this.checked;
      },
      set: function (newValue) {
        this.$emit("change", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input--checkbox::v-deep {
  &.active,
  &.v-input--is-label-active {
    .v-input--selection-controls__input {
      background: map-get($primary, base);
      border-color: map-get($primary, base);
    }
    .v-icon {
      color: white !important;
    }
  }
  &.v-input--is-disabled {
    .v-input--selection-controls__input {
      background-color: map-get($gray, lighten2);
      border-color: map-get($gray, lighten2);
    }
  }
  &.v-input--indeterminate.v-input--is-disabled {
    opacity: 1 !important;
    .v-icon {
      svg {
        color: map-get($primary, lighten1);
      }
    }
  }
  &.v-input--indeterminate:not(.active) {
    .v-icon {
      svg {
        color: map-get($primary, lighten1);
      }
    }
    .v-input--selection-controls__input {
      border-color: map-get($primary, base);
      background-color: map-get($primary, lighten2);
    }
    &.v-input--is-disabled {
      opacity: 1 !important;
    }
  }
  .v-input--selection-controls__input {
    background-color: var(--v-checkbox-base);
    width: 22px;
    height: 22px;
    border-radius: 4px;
    border: 1px solid map-get($gray, lighten2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    + label {
      margin-left: 16px;
    }
  }
  .v-icon {
    svg {
      width: 20px;
      height: 20px;
      margin-top: 2px;
      margin-left: 1px;
      transition: color 0.3s ease;
    }
  }
  &.labellg {
    .v-label {
      font-size: $font-size-base;
      line-height: $line-height-base;
    }
  }
  .v-label {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }
  &.v-input--checkbox-error {
    &.active,
    &.v-input--is-label-active {
      .v-input--selection-controls__input {
        background: map-get($error, base);
        border-color: map-get($error, base);
      }
      .v-icon {
        color: white !important;
      }
    }
    &.v-input--indeterminate.v-input--is-disabled {
      opacity: 1 !important;
      .v-icon {
        svg {
          color: map-get($error, lighten1);
        }
      }
    }
    &.v-input--indeterminate:not(.active) {
      .v-icon {
        svg {
          color: map-get($error, lighten1);
        }
      }
      .v-input--selection-controls__input {
        border-color: map-get($error, base);
        background-color: map-get($error, lighten2);
      }
      &.v-input--is-disabled {
        opacity: 1 !important;
      }
    }
  }
}
.checkbox {
  border-radius: 4px;
}
.v-input::v-deep {
  margin: 0;
  padding: 0;
  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }
}
</style>