<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" stroke="#E71D1D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 8L8 12" stroke="#E71D1D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 8L12 12" stroke="#E71D1D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
    
<script>
export default {};
</script>

<style>
</style>