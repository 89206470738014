<template>
  <div class="d-flex align-center skeleton-row">
    <div class="checkbox-container d-flex align-center">
      <v-skeleton-loader class="checkbox" type="chip" />
    </div>
    <div class="name-container d-flex align-center mr-4">
      <v-skeleton-loader class="avatar mr-4" type="avatar" />
      <v-skeleton-loader class="names" type="heading, text" />
    </div>
    <div class="status-container mr-4 d-flex align-center mr-4">
      <v-skeleton-loader class="status" type="avatar" />
    </div>
    <div class="text-continer mr-4 d-flex align-center">
      <v-skeleton-loader class="text" type="text" />
    </div>
    <div class="text-continer mr-4 d-flex align-center">
      <v-skeleton-loader class="text" type="text" />
    </div>
    <div class="text-continer mr-4 d-flex align-center">
      <v-skeleton-loader class="text" type="text" />
    </div>
    <div class="tags-container mr-4 d-flex align-center">
      <v-skeleton-loader class="tags" type="chip@3" />
    </div>
    <div class="actions-container d-flex justify-end align-center">
      <v-skeleton-loader class="actions" type="avatar" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.skeleton-row {
  margin: 0 -16px;
  padding: 0 24px;
  width: calc(100% + 28px);
  min-height: 66px;
  max-height: 66px;
  &:first-child {
    border-top: 1px solid var(--v-gray-lighten2);
  }
  &:last-child {
    border-bottom: 1px solid transparent;
  }
}
.checkbox-container {
  width: 46px;
}

.v-skeleton-loader::v-deep {
  &.checkbox {
    .v-skeleton-loader__chip {
      width: 22px;
      height: 22px;
      position: relative;
      border-radius: 4px;
      border: 1px solid var(--v-gray-lighten2);
      background-color: var(--v-skeleton-base);
    }
  }
  &.avatar {
    .v-skeleton-loader__avatar {
      width: 40px;
      height: 40px;
    }
  }
  &.names {
    width: 240px;
    .v-skeleton-loader__text {
      width: 205px;
      height: 10px;
      border-radius: 3px;
      margin-top: 4px;
    }
    .v-skeleton-loader__heading {
      height: 24px;
      width: 143px;
      border-radius: 3px;
    }
  }
  &.status {
    width: 80px;
    .v-skeleton-loader__avatar {
      height: 18px;
      width: 18px;
      border-radius: 50%;
    }
  }
  &.text {
    width: 126px;
    .v-skeleton-loader__text {
      width: 80px;
    }
  }
  &.tags {
    display: flex;
    width: 299px;
    .v-skeleton-loader__chip {
      margin-right: 8px;
      width: 68px;
      height: 20px;
    }
  }
  &.actions {
    width: 72px;
    .v-skeleton-loader__avatar {
      height: 38px;
      width: 38px;
      margin-left: 34px;
    }
  }
}
</style>