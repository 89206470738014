const ActionModalMixin = {
    data: function() {
        return {
            modalOptions: {},
            modalRender: 0,
        }
    },
    methods: {
        resetModal() {
            this.modalRender++;
            this.modalOptions = {
                open: false,
                persistent: false,
                message: "",
                icon: "",
                submitting: false,
                submittingSuccess: "",
                submittingError: "",
                submittingProgressLog: "",
                formFields: [],
                buttons: [
                    {
                        label: this.$t('button.cancel'),
                        type: "cancel",
                        color: "normal",
                        onclick: () => {
                            this.modalClose();
                        },
                    },
                ],
                item: null,
                type: ""
            };
        },
        modalClose() {
            this.modalOptions.open = false;
        },
    }
}

export default ActionModalMixin