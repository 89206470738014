<template>
  <v-dialog
    hide-overlay
    :overlay-opacity="0"
    transition="fade-transition"
    persistent
    ref="dialog"
    v-model="isOpen"
    absolute
  >
    <div class="card-overlay" @click="$refs.dialog.animateClick()" />

    <v-card style="width: 600px" class="" v-if="isOpen">
      <v-card-text height="800px" class="px-8 pt-8">
        <div class="mb-8 d-flex align-start">
          <div class="d-flex flex-column">
            <span class="font-weight-light p-1 base--text">
              {{ $t('heading.onboarding.import.ftp.title') }}
            </span>
            <h4 class>{{ $t('heading.onboarding.import.ftp.subtitle') }}</h4>
          </div>

          <v-btn x-small icon @click="$emit('removeModal')" class="ml-auto">
            <v-icon size="24" color="gray">$close</v-icon>
          </v-btn>
        </div>

        <v-divider class="mb-5" />

        <p class="p-2 gray--text text--darken-2 mb-5 font-weight-light">
          {{ $t('heading.onboarding.import.ftp.info') }}
        </p>

        <v-form ref="form" class="form-box">
          <div class="full">
            <span
              class="gray--text text--darken-1 mb-1"
              :class="hostnameError ? 'error--text' : ''"
            >
              {{ $t('form.label.hostname') }}
            </span>
            <v-text-field
              v-model="hostname"
              outlined
              hide-details
              :error="hostnameError ? true : false"
              dense
              :rules="[
                (v) => !!v ||
                  this.$t('form.validation.required', {field: $t('form.label.hostname')}),
                (v) => (v && v.length > 0) ||
                  this.$t('form.validation.required', {field: $t('form.label.hostname')}),
                (v) => (v && v.length < 256) ||
                  this.$t('form.validation.tooLong', {field: $t('form.label.hostname')}),
              ]"
              class="mt-1 mb-0 v-input--md"
              placeholder="ftp.source.example.com"
            >
            </v-text-field>
          </div>

          <div class="half">
            <span
              class="gray--text text--darken-1 mb-1"
              :class="protocolError ? 'error--text' : ''"
            >
              {{ $t('form.label.protocol') }}
            </span>
            <v-select
              :items="availableProtocols"
              v-model="protocol"
              :error="protocolError ? true : false"
              outlined
              dense
              hide-details
              class="mt-1 mb-0"
              :rules="[
                (v) => !!v ||
                  this.$t('form.validation.required', {field: $t('form.label.protocol')}),
                (v) => (v && v.length > 0) ||
                  this.$t('form.validation.required', {field: $t('form.label.protocol')}),
                (v) => availableProtocols.includes(v) ||
                  this.$t('form.validation.invalid', {field: $t('form.label.protocol')}),
              ]"
              :menu-props="{
                offsetY: true,
              }"
            />
          </div>
          <div class="half">
            <span
              class="gray--text text--darken-1 mb-1"
              :class="portError ? 'error--text' : ''"
            >
              {{ $t('form.label.serverPort') }}
            </span>
            <v-text-field
              v-model="port"
              outlined
              :error="portError ? true : false"
              hide-details
              type="number"
              :rules="[
                (v) => !!v ||
                  this.$t('form.validation.required', {field: $t('form.label.serverPort')}),
                (v) => (v && v > 0 && v < 65536) ||
                  this.$t('form.validation.integer', {
                    field: $t('form.label.serverPort'),
                    firstNumber: 1,
                    secondNumber: 65536
                  }),
                (v) => (v && v % 1 === 0) ||
                  this.$t('form.validation.integer', {field: $t('form.label.serverPort')}),
              ]"
              dense
              class="mt-1 mb-0 v-input--md"
              placeholder="21"
            />
          </div>
          <template v-if="protocol == 'SFTP'">
            <div class="full">
              <span
                class="gray--text text--darken-1 mb-1"
                :class="authMethodError ? 'error--text' : ''"
              >
                {{ $t('form.label.authenticationMethod') }}
              </span>
              <v-select
                :items="authMethods"
                v-model="authMethod"
                :error="authMethodError ? true : false"
                outlined
                dense
                hide-details
                class="mt-1 mb-0"
                :rules="[
                  (v) => !!v ||
                    this.$t('form.validation.required', {field: $t('form.label.authenticationMethod')}),
                  (v) =>
                    (v && v.length > 0) ||
                    this.$t('form.validation.required', {field: $t('form.label.authenticationMethod')}),
                ]"
                :menu-props="{
                  offsetY: true,
                }"
              />
            </div>
            <div class="half">
              <span
                class="gray--text text--darken-1 mb-1"
                :class="sftpUsernameError ? 'error--text' : ''"
              >
                {{ $t('form.label.sftp.user') }}
              </span>
              <v-text-field
                v-model="sftpUsername"
                outlined
                :rules="[
                  (v) => !!v ||
                    this.$t('form.validation.required', {field: $t('form.label.sftp.user')}),
                  (v) => (v && v.length > 0) ||
                    this.$t('form.validation.required', {field: $t('form.label.sftp.user')}),
                  (v) => (v && v.length < 256) ||
                    this.$t('form.validation.tooLong', {field: $t('form.label.sftp.user')}),
                ]"
                hide-details
                :error="sftpUsernameError ? true : false"
                dense
                class="mt-1 mb-0 v-input--md"
                :placeholder="$t('form.placeholder.sftp.user')"
              />
            </div>

            <div class="half">
              <span
                v-if="authMethod == 'password'"
                class="gray--text text--darken-1 mb-1"
                :class="sftpPasswordError ? 'error--text' : ''"
              >
                {{ $t('form.label.sftp.password') }}
              </span>
              <v-text-field
                v-if="authMethod == 'password'"
                v-model="sftpPassword"
                outlined
                hide-details
                :rules="[
                  (v) => !!v ||
                    this.$t('form.validation.required', {field: $t('form.label.sftp.password')}),
                  (v) => (v && v.length > 0) ||
                    this.$t('form.validation.required', {field: $t('form.label.sftp.password')}),
                  (v) => (v && v.length < 256) ||
                    this.$t('form.validation.tooLong', {field: $t('form.label.sftp.password')}),
                ]"
                :error="sftpPasswordError ? true : false"
                dense
                class="mt-1 mb-0 v-input--md"
                type="password"
                :placeholder="$t('form.placeholder.sftp.password')"
              />
            </div>
            <template v-if="authMethod == 'ssh_key'">
              <div class="half">
                <span
                  class="gray--text text--darken-1 mb-1"
                  :class="sftpPrivateKeyError ? 'error--text' : ''"
                >
                  {{ $t('form.label.privateKey') }}
                </span>
                <v-text-field
                  v-model="sftpPrivateKey"
                  outlined
                  :rules="[
                    (v) => !!v ||
                      this.$t('form.validation.required', {field: $t('form.label.privateKey')}),
                    (v) => (v && v.length > 0) ||
                      this.$t('form.validation.required', {field: $t('form.label.privateKey')}),
                  ]"
                  hide-details
                  :error="sftpPrivateKeyError ? true : false"
                  dense
                  class="mt-1 mb-0 v-input--md"
                  type="password"
                  :placeholder="$t('form.placeholder.privateKey')"
                />
              </div>

              <div class="half">
                <span
                  class="gray--text text--darken-1 mb-1"
                  :class="sftpPassphraseError ? 'error--text' : ''"
                >
                  {{ $t('form.label.passphrase') }} ({{$t('general.optional')}})
                </span>
                <v-text-field
                  v-model="sftpPassphrase"
                  outlined
                  hide-details
                  :rules="[]"
                  :error="sftpPassphraseError ? true : false"
                  dense
                  class="mt-1 mb-0 v-input--md"
                  type="password"
                  :placeholder="$t('form.placeholder.passphrase')"
                />
              </div>
            </template>
          </template>
          <template v-else>
            <div class="half">
              <span
                class="gray--text text--darken-1 mb-1"
                :class="usernameError ? 'error--text' : ''"
              >
                {{ $t('form.label.ftp.user') }}
              </span>
              <v-text-field
                v-model="username"
                outlined
                :rules="[
                  (v) => !!v ||
                    this.$t('form.validation.required', {field: $t('form.label.ftp.user')}),
                  (v) => (v && v.length > 0) ||
                    this.$t('form.validation.required', {field: $t('form.label.ftp.user')}),
                  (v) => (v && v.length < 256) ||
                    this.$t('form.validation.tooLong', {field: $t('form.label.ftp.user')}),
                ]"
                hide-details
                :error="usernameError ? true : false"
                dense
                class="mt-1 mb-0 v-input--md"
                :placeholder="$t('form.placeholder.ftp.user')"
              />
            </div>

            <div class="half">
              <span
                class="gray--text text--darken-1 mb-1"
                :class="passwordError ? 'error--text' : ''"
              >
                {{ $t('form.label.ftp.password') }}
              </span>
              <v-text-field
                v-model="password"
                outlined
                hide-details
                :rules="[
                  (v) => !!v ||
                    this.$t('form.validation.required', {field: $t('form.label.ftp.password')}),
                  (v) => (v && v.length > 0) ||
                    this.$t('form.validation.required', {field: $t('form.label.ftp.password')}),
                  (v) => (v && v.length < 256) ||
                    this.$t('form.validation.tooLong', {field: $t('form.label.ftp.password')}),
                ]"
                :error="passwordError ? true : false"
                dense
                class="mt-1 mb-0 v-input--md"
                type="password"
                :placeholder="$t('form.placeholder.ftp.password')"
              />
            </div>
          </template>

          <div class="full">
            <span
              class="gray--text text--darken-1 mb-1"
              :class="installedPathError ? 'error--text' : ''"
            >
              {{ $t('form.label.installedPath') }}
            </span>
            <v-text-field
              v-model="installedPath"
              outlined
              :rules="[]"
              hide-details
              :error="installedPathError ? true : false"
              dense
              class="mt-1 mb-0 v-input--md"
              placeholder="wp"
            />
          </div>
        </v-form>
        <v-scale-transition>
          <div class="error-box mt-5" v-if="formError && !connectionTestLoading">
            <v-icon color="error" size="20" class="mr-2">$alertcircle</v-icon>
            <span class="error--text">
              <b class="error--text">
                <i18next :translation="$t('message.alert.failed')">
                  <template #message>
                    <span class="font-weight-medium">{{ formError }}</span>
                  </template>
                </i18next>
              </b>
            </span>
          </div>
        </v-scale-transition>
      </v-card-text>
      <v-card-actions class="pt-8 d-flex align-center justify-space-between">
        <div>
          <step-indicator :currentStep="3" :maxSteps="4" />
        </div>
        <div class="d-flex align-center">
          <v-btn
            large
            @click="$emit('removeModal')"
            text
            elevation="0"
            class="mr-3"
          >
            <span class="font-weight-bold gray--text text--darken-1">
              {{ $t('button.cancel') }}
            </span>
          </v-btn>
          <v-btn
            :loading="connectionTestLoading"
            large
            @click="handleContinueButtonClick"
            color="primary"
            elevation="0"
          >
            <span class="font-weight-bold">
              {{ $t('button.continue') }}
              <v-icon size="20" class="mr-0">$arrowright</v-icon>
            </span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StepIndicator from "../StepIndicator.vue";
import Api from "@/apis/Api";

export default {
  components: {
    StepIndicator,
  },
  props: {
    isOpen: Boolean,
    importInstanceData: Object,
  },
  data() {
    return {
      hostname: "",
      hostnameError: "",
      protocol: "FTP",
      protocolError: "",
      availableProtocols: ["FTP", "FTPS", "SFTP"],
      availableProtocolsError: "",
      port: 21,
      portError: "",
      username: "",
      usernameError: "",
      password: "",
      passwordError: "",
      installedPath: "",
      installedPathError: "",
      authMethod: "password",
      authMethodError: "",
      authMethods: [
        { text: this.$t('form.option.password'), value: "password" },
        { text: this.$t('form.option.sshKey'), value: "ssh_key" },
      ],
      sftpUsername: "",
      sftpUsernameError: "",
      sftpPassword: "",
      sftpPasswordError: "",
      sftpPrivateKey: "",
      sftpPrivateKeyError: "",
      sftpPassphrase: "",
      sftpPassphraseError: "",
      formError: "",
      connectionTestLoading: false,
    };
  },
  computed: {
    isDemo() {
      return this.$store.state.home.user.email == "demo@panelalpha.com";
    },
    serviceId() {
      if (
        this.activeService &&
        this.activeService.instance_count < this.activeService.instance_limit
      ) {
        return this.activeService.id;
      }
      return 0;
    },
    activeService() {
      for (let service of this.$store.state.home.user.services) {
        if (service.status == "active") {
          return service;
        }
      }
      return null;
    },
  },
  methods: {
    async handleContinueButtonClick() {
      try {
        if (
          !this.$refs.form.validate() &&
          (this.hostname === "" ||
            this.protocol === "" ||
            this.port === "" ||
            this.username === "" ||
            this.password === "" ||
            this.installedPath === "")
        ) {
          throw this.$t('notification.emptyFields');
        }

        this.connectionTestLoading = true;

        let result;
        if (this.isDemo) {
          result = await this.mockConnectionTest();
        } else {
          result = await this.runConnectionTest();
        }
        if (result) {
          this.$emit("next", result);
        } else {
          throw this.$t('notification.connectionFailed');
        }
      } catch (error) {
        this.formError = error;
      } finally {
        // Wait 3s to prevent bruteforce
        // setTimeout(() => {
        this.connectionTestLoading = false;
        // }, 3000);
      }
    },
    async mockConnectionTest() {
      return new Promise((resolve, reject) => {
        if (this.hostname !== "reject") {
          setTimeout(() => {
            resolve({
              data: { url: "https://example.com" },
              meta: {
                nameservers: ["ns1.example.com", "ns2.example.com", "ns3.example.com"],
              },
            });
          }, 2000);
        } else {
          reject(false);
        }
      });
    },
    async runConnectionTest() {
      return new Promise((resolve, reject) => {
        if (!this.activeService) {
          reject(this.$t('notification.noService'));
        }
        if (this.activeService.instance_count >= this.activeService.instance_limit) {
          reject(this.$t('notification.limitReached'))
        }
        let protocol = this.protocol.toLowerCase();
        let params = {
          service_id: this.serviceId,
          url: this.importInstanceData.url,
          protocol: protocol,
          path: this.installedPath,
        };
        switch (protocol) {
          case "ftp":
            params.ftp_host = this.hostname;
            params.ftp_port = this.port;
            params.ftp_username = this.username;
            params.ftp_password = this.password;
            break;
          case "ftps":
            params.ftps_host = this.hostname;
            params.ftps_port = this.port;
            params.ftps_username = this.username;
            params.ftps_password = this.password;
            break;
          case "sftp":
            params.sftp_host = this.hostname;
            params.sftp_port = this.port;
            params.sftp_auth_method = this.authMethod;
            params.sftp_username = this.sftpUsername;
            if (this.authMethod == "password") {
              params.sftp_password = this.sftpPassword;
            } else {
              params.sftp_private_key = this.sftpPrivateKey;
              if (this.sftpPassphrase) {
                params.sftp_private_key_passphrase = this.sftpPassphrase;
              }
            }
            break;
        }
        Api.post(`/instances/import/import`, params)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(Api.getErrorMessage(error));
          });
      });
    },
    resetData() {
      this.hostname = "";
      this.hostnameError = "";
      this.protocol = "FTP";
      this.protocolError = "";
      this.availableProtocols = ["FTP", "FTPS", "SFTP"];
      this.availableProtocolsError = "";
      this.port = 21;
      this.portError = "";
      this.username = "";
      this.usernameError = "";
      this.password = "";
      this.passwordError = "";
      this.installedPath = "";
      this.installedPathError = "";

      this.formError = "";

      this.connectionTestLoading = false;
    },
  },
  watch: {
    isOpen: function (value) {
      this.resetData();

      if (value) {
        this.$nextTick(() => {
          this.$store.dispatch("lockBodyScroll");
        });
      } else {
        this.$store.dispatch("unlockBodyScroll");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .v-dialog {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.v-card::v-deep {
  .v-card__text {
    -webkit-line-clamp: unset !important;
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    background-color: #fff;
  }
  .form-box {
    border: 1px solid var(--v-gray-lighten4);
    border-radius: 8px;
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px 12px;
    .full {
      grid-column: 1 / 3;
    }
  }
  .error-box {
    background-color: var(--v-error-lighten5);
    border-radius: 8px;
    padding: 16px;
    display: flex;
  }

  @media (max-width: 480px) {
    .v-card__text,
    .v-card__actions {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}
</style>
